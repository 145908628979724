import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import determineBMPStatus from '../../utils/determineBMPStatus'
import generateLetterLabel from '../../utils/generateLetterLabel'

/*import BMPEntry from '../../EntryComponents/BMPEntry'
import EmployeeEntry from '../../EntryComponents/EmployeeEntry'*/

import BMPLocationEntry from '../../EntryComponents/BMPLocationEntry';
import RequiredCorrectiveActions from '../../EntryComponents/RequiredCorrectiveActions';
import Document from '../../EntryComponents/generic/Document';

export default function BMPForm(props) {
	let {handleChange, handleBMPChange, cancelBMPFlow, completeReport, reportState, saveReportForLater} = props;
	let {sendingReport, loggedBMPs, activeBMPIndex} = reportState;

    let createActiveBMP = () => {
        console.log("BMPForm loggedBMPs:", loggedBMPs);
        console.log("BMPForm activeBMPIndex:", activeBMPIndex);
        return _.cloneDeep(loggedBMPs[activeBMPIndex])
    }

    let setActiveBMP = (bmp) => {
        console.log("Calling setActiveBMP");
        handleBMPChange(bmp)
        setActiveBMPDirect(bmp);
    }

    let setCorrected = (val) => {
        //handleChange('currentBMPCorrected', val);
        setCorrectedDirect(val)
    }

    let moveOn = () => {

        let {conditionDocumentation, correctionDocumentation, letterLabel} = activeBMP;
        if (!letterLabel){
            activeBMP.letterLabel = generateLetterLabel(activeBMP, reportState, handleChange);
            setActiveBMP(_.cloneDeep(activeBMP))
        }

        let currentValidConditions = true;
        let currentValidCorrections = true;

        if (conditionDocumentation.description == "" && conditionDocumentation.photos.length == 0){
            currentValidConditions = false;
        } else {
            currentValidConditions = true;
        }

        if (corrected){
            if (correctionDocumentation.description == "" && correctionDocumentation.photos.length == 0){
                currentValidCorrections = false;
            } else {
                currentValidCorrections = true;
            }

        }

        console.log("moveOn currentValidConditions:", currentValidConditions, "currentValidCorrections:", currentValidCorrections, "corrected:", corrected);

        if (currentValidConditions && corrected && currentValidCorrections){
            console.log("moveOn to go to BMPEventSummaryComplete")
            //handleChange("phase", "BMPEventSummaryComplete");
            handleChange("phase", "LoggedEventsList");
        } else if (currentValidConditions && (!corrected || !currentValidCorrections)){
            console.log("moveOn to go to BMPEventSummaryInomplete")
            //handleChange("phase", "BMPEventSummaryComplete");
            handleChange("phase", "LoggedEventsList");
        } else {
            console.log("moveOn to not move on");
            if (currentValidCorrections != validCorrections) setValidCorrections(currentValidCorrections);
            if (currentValidConditions != validConditions) setValidConditions(currentValidConditions)
        }
    }
   
    let [activeBMP, setActiveBMPDirect] = useState(createActiveBMP());
    let [corrected, setCorrectedDirect] = useState(false);
    let [validConditions, setValidConditions] = useState(true);
    let [validCorrections, setValidCorrections] = useState(true);

	return(<div>

        <InspectionWrapper
          title="Log BMP Event"
          navButtons={[
            {
              className: "btn-secondary", 
              onClick: cancelBMPFlow,
              label: <><i className="fa fa-angle-left mr-1"></i> CANCEL</>
            },
            {
              className: "btn-primary",
              onClick: moveOn,
              label: <>SAVE <i class="fa fa-angle-right ml-1"></i></>
            },

          ]}
          isBMPFlow={true}
        >

                <div class="col-12 mb-4 p-3 ml-2 border-bottom">
                    <p class="mb-1 fs-20"><i class="fa fa-cube px-2" aria-hidden="true"></i><strong>{activeBMP.type}: {activeBMP.name}</strong></p>
                </div>        


                <div class="col-12">
                    <div class="fs-17 alert alert-info"><strong>Click on the map to plot the location of the BMP:</strong></div>
                    <BMPLocationEntry activeBMP={activeBMP} setActiveBMP={setActiveBMP} reportState={reportState} />
                </div>


                <Document
                    label="BMP Conditions"
                    uniqueID="BMPFormBMPCondition"
                    includeInitials={false}
                    initVal={activeBMP.conditionDocumentation}
                    valid = {validConditions}
                    onChange={(passedObj) => {
                        console.log("Document bmpForm OnChange")
                        let cloneBMP = _.cloneDeep(activeBMP);
                        cloneBMP.conditionDocumentation = _.cloneDeep(passedObj);
                        setActiveBMP(cloneBMP);
                    }}
                />

               <RequiredCorrectiveActions
                    initCas={activeBMP.cas}
                    callback={(passedCas) => {
                         let cloneBMP = _.cloneDeep(activeBMP);

                        if (passedCas == null){
                            cloneBMP.cas = null;
                            cloneBMP.status = "Documentation"

                        } else {
                            cloneBMP.cas = _.cloneDeep(passedCas);
                        }
                        
                        setActiveBMP(cloneBMP);
                    }}
               />

            {activeBMP.status !== "Documentation" ? <>
                <div class="col-12 mb-4 px-4 border-bottom">
                    <p class="mb-1 fs-17 mb-4 float-left"><strong>Corrected during Inspection?</strong> (optional) </p>
                    <button type="button" class="btn btn-xs btn-round btn-secondary my-1 float-right" onClick={() => setCorrected(!corrected)}>YES, DOCUMENT CORRECTION</button>
                </div>

                {corrected ? 
                    <div class="border-primary col-12">
                        <Document
                            label="Corrective Action"
                            uniqueID="BMPFormCorrectiveAction"
                            initVal={activeBMP.correctionDocumentation}
                            valid = {validCorrections}
                            includeInitials={true}
                            onChange={(passedObj) => {
                                let cloneBMP = _.cloneDeep(activeBMP);
                                cloneBMP.correctionDocumentation = _.cloneDeep(passedObj);
                                setActiveBMP(cloneBMP);
                            }}
                        />
                    </div>
                : null}
            </> : null}
              


        </InspectionWrapper>
    </div>)

}
    


import _ from 'lodash';

export default function addBMPToLoggedBMPs(passedLoggedBMPs, passedBMP, handleChange){
	console.log("calling addBMPToLoggedBMPs ==================================================")
	console.log("addBMPToLoggedBMPs passedLoggedBMPs:", passedLoggedBMPs);
	let loggedBMPs = _.cloneDeep(passedLoggedBMPs);
	let bmp = _.cloneDeep(passedBMP);
	bmp.bmpIndex = loggedBMPs.length;

	loggedBMPs.push(bmp);
	console.log("addBMPToLoggedBMPs final loggedBMPs:", loggedBMPs);
	handleChange("loggedBMPs", loggedBMPs);

}
import React, {Fragment, useState, useEffect} from 'react';
import _ from 'lodash';
import {
	isMobileOnly
} from 'react-device-detect'

export default function BMPVerificationEntry({passedBMP, callback}){

	let [bmp, setBMPDirect] = useState(_.cloneDeep(passedBMP));

	let buttons = [
		{val: "good", label: "Good Condition", icon: "check", color: "success"},
		{val: "na", label: "Not Applicable", icon: "na", color: "secondary"},
	]

	let setBMPStatus = (newStatus) => {
		let cloneBMP = _.cloneDeep(bmp);
		cloneBMP.status = newStatus;
		callback(newStatus);
		setBMPDirect(cloneBMP)
	}

	let renderButtons = () => {
		return buttons.map(({val, label, color, icon}, key) => {
			return <button type="button" 
				className={"btn btn-xs mr-2 btn" + (bmp.status == val ? "" : "-outline") + "-"+color + (isMobileOnly ? "" : " mt-2 float-right")}
				onClick={() => setBMPStatus(val)}
				key={key}
			>
				<i className={"ti ti-"+icon+" strong mr-2"} aria-hidden="true"></i>{label}
			</button>
		});
	}


	return(<>
		<div className="pt-3">
			<span className="mr-4"><a className="fs-20 fw-700">{bmp.name}</a></span>
			{isMobileOnly ? 
				<div className="flexbox">
					{renderButtons()}
				</div>
			:
				renderButtons()
			}
		</div>

	</>)

}
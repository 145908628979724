import React, {Fragment, useState} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import Checkboxes from '../../EntryComponents/generic/Checkboxes'
import Dropdown from '../../EntryComponents/generic/Dropdown'
import DatePicker from 'react-datepicker' 
import textLimits from '../../constants/textLimits'

/*Third Page*/

export default function WeatherAndSoil(props){

	let {reportState, handleChange, saveReportForLater} = props;
  let {flexdata} = reportState;

  let createTemperatureList = () => {
    let list = [];

    for (var i = -40; i <= 120; i++){
      list.push(i + "F");
    }

    return list;
  }

  let [temperatureList, setTemperatureList] = useState(createTemperatureList())

	return(<>
		<InspectionWrapper
      title="Weather Conditions"
      navButtons={[
        {
          className: "btn-secondary", 
          onClick: () => handleChange('phase', 'InspectionInfo'), 
          label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
        },
        {
          className: "btn-outline-primary",
          onClick: saveReportForLater,
          label: "Save for Later"
        },
        {
          className: "btn btn-primary",
          onClick: () => handleChange("phase", "ProjectPhase"),
          label: <>CONTINUE <i class="fa fa-angle-right ml-1"></i></>
        }
      ]}
    >

        <Dropdown
          title="Temperature (degrees F)"
          wrapperClassName="form-group contrast"
          value={flexdata.temperature}
          onChange={(val) => handleChange("temperature", val)}
          placeholder="Temperature (degrees F)"
          dropdownValues={temperatureList}
          name="temperature"
        />

        <div class="form-group strong fs-16">

        	<Checkboxes
        		buttons={[
        			{label: "Dry", value: "dry"}, 
        			{label: "Variable", value: "variable"}, 
        			{label: "Wet", value: "wet"},
        			{label: "Frozen or snow covered", value: "frozen"}, 
        			{label: "Frozen (Thaw predicted in next week)", value: "frozenthaw"}, 
        			{label: "Melting Snow/Slush", value: "melting"}
      		]}
      		title={<label class="text-primary">Antecedent Soil Moisture</label>}
      		labelClassName={"custom-control-label strong fs-16 p-0 ml-2 pl-2"}
      		defaultCheckboxVal={flexdata.soilmoisture}
      		onChange={(val) => handleChange("soilmoisture", val)}
        	/>

        </div>

        <div class="form-group contrast">
          <label class="text-primary">Last Rainfall Date</label>
          <DatePicker
      			className={"form-control strong fs-16"}
      			dateFormat="MM/dd/yyyy hh:mm aa"
      			selected = {flexdata.lastrainfalldate || null}
      			onChange={ date => handleChange("lastrainfalldate", date) }
      			placeholderText="Date"
      			showTimeSelect
           />
        </div>

        <div class="form-group contrast">
          <label class="text-primary">Last Rainfall Depth</label>
          <input class="form-control strong fs-16" 
          	type="text" 
          	placeholder="Depth (inches)"
          	value={flexdata.lastrainfalldepth}
          	onChange={(e) => handleChange("lastrainfalldepth", e.target.value)}
            maxLength={textLimits.textInputCharLimitStandard}
      	/>
        </div>
    </InspectionWrapper>
	</>)

}
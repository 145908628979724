import _ from 'lodash';
import addBMPToLoggedBMPs from './addBMPToLoggedBMPs';
import removeLoggedBMPByIdpractice from './removeLoggedBMPByIdpractice';

export default function processAutoBMPs(currentState, prevState, handleChange){

	let relevantPhases = ["ProjectPhase"]

	for (var i = 0; i < relevantPhases.length; i++){
		let phaseToCheck = relevantPhases[i];

	    if (currentState.flexdata.phase == phaseToCheck){
	        console.log("processAutoBMPs", "State changed on "+phaseToCheck+" page, checking autoBMP data");

	        //State updates to pull in auto BMP
	        let autoBMPTriggerList = getAutoBMPTriggerList(phaseToCheck);

	        for (var i = 0; i < autoBMPTriggerList.length; i++){
	            let {key, id} = autoBMPTriggerList[i];

	            toggleAutoBMP(currentState.flexdata[key].toLowerCase(), prevState.flexdata[key].toLowerCase(), currentState.loggedBMPs, currentState.autoBMPsList, id, handleChange);   
	        }
	    }
    }

}

function getAutoBMPTriggerList(phase){

    if (phase == "ProjectPhase"){
    	return [
            {key: "erosionplanaccessible", id: -1},
            {key: "permitcertificateposted", id: -2},
        ]
    }
}

function toggleAutoBMP (currentVal, prevVal, loggedBMPs, autoBMPsList, id, handleChange) {

    console.log("processAutoBMPs toggleAutoBMP params: currentVal", currentVal, "prevVal", prevVal, "loggedBMPs", loggedBMPs, "autoBMPsList", autoBMPsList, "id", id, "handleChange", handleChange);

    let loggedBMPsIds = loggedBMPs.map(({idpractice}) => {return (idpractice ? idpractice : 0)});
    let autoBMPLogged = (loggedBMPsIds.indexOf(id) !== -1);

    console.log("processAutoBMPs toggleAutoBMP loggedBMPsIds:", loggedBMPsIds);

    if (currentVal == "no" && (prevVal == "yes" || prevVal == undefined || prevVal == null || prevVal == "")) {

    	if (!autoBMPLogged){
    		logAutoBMP(loggedBMPs, autoBMPsList, id, handleChange);
    	}

    } else if (currentVal == "yes" && prevVal == "no") {

    	if (autoBMPLogged){
    		removeAutoBMP(loggedBMPs, id, handleChange)
    	}
    }
}

function logAutoBMP(loggedBMPs, autoBMPsList, id, handleChange){
	console.log("processAutoBMPs logAutoBMP params: loggedBMPs", loggedBMPs, "autoBMPsList", autoBMPsList, "id", id)
	let bmpToAdd = autoBMPsList[(id*-1)-1]; //Multiplies the id by -1 and subtracts 1. So id==-1 corresponds to index==0, id==-2 corresponds to index==1, etc
	console.log("processAutoBMPs logAutoBMP bmpToAdd:", bmpToAdd);
	addBMPToLoggedBMPs(loggedBMPs, bmpToAdd, handleChange);
}

function removeAutoBMP(loggedBMPs, idpractice, handleChange){
	removeLoggedBMPByIdpractice(loggedBMPs, idpractice, handleChange);
}
import React from 'react'

const Footer = () => {
  return (
    <footer className="footer p-0">
      <div className="col-12 flexbox">
        <div className="col-md-6">
          <div className="nav nav-left">
          </div>
        </div>
        <div className="col-12 col-md-6 text-right">
          <a className="nav-link" href="#"><small>Earth Tight by Earth Ideal ©2022</small></a>
        </div>
      </div>

    </footer>

  )
}

export default Footer

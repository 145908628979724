import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
//import App from './App';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// Components
import UserSettings from './components/UserSettings'
import Main from './components/Main'

import InspectionAcknowledgement from './components/Reports/InspectionReports/InspectionAcknowledgement'

import Login from './components/Login'
import RegisterCore from './components/Register/RegisterCore'
import VerifyRegisterCore from './components/Register/VerifyRegisterCore'
import LogoutCore from './components/Logout/LogoutCore';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { url } from './constants'

const LoggedIn =(
  <BrowserRouter>
    <Switch>      
      <Route path="/user-settings"                component={ UserSettings } />
      <Route path="/logout"                       render = {(props) => <LogoutCore home={url.frontend} /> } /> 
      <Route path="/app/:page"                    render = {(props) => <Main match={props.match} history={props.history} />} />
      <Route 
        path="/inspection-acknowledgement/:verificationtoken" component = {InspectionAcknowledgement} 
      />
      <Redirect from="/" to="/app/loading" />    
    </Switch>
  </BrowserRouter>
)

const LoggedOut = (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" render={(props) => <RegisterCore home={url.frontend}  logo="/img/logo/EarthTightLogo.jpeg" /> } />
      <Route path="/account-confirmation" render={(props) => <VerifyRegisterCore home={url.frontend} logo="/img/logo/EarthTightLogo.jpeg" /> } />
      <Route 
        path="/inspection-acknowledgement/:verificationtoken" component = {InspectionAcknowledgement} 
      />
      <Redirect to="/login" />
    </Switch>}
  </BrowserRouter>
)

Amplify.configure(awsconfig);

Auth.currentAuthenticatedUser().then(async user => {
    console.log("user:", user);
    const userJWT = user.signInUserSession.idToken.jwtToken;
    
    if(userJWT!==null){
      localStorage.setItem('token', userJWT);
    }

    ReactDOM.render(LoggedIn, document.getElementById('root'));

}).catch(err => {
    console.log("Rendering logged out router with err ",err);
    if (err !== "not authenticated"){
      console.log("err:", err);
    } else {
      ReactDOM.render(LoggedOut, document.getElementById('root'));
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

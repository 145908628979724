import _ from 'lodash';

export function getSpecificEntryFromGroupsArray({groups, identry, idkey}){

	console.log("Calling getSpecificEntryFromGroupsArray with params groups", groups, "identry", identry, "idkey", idkey)

	let requestedEntry = false;
	let groupIndex = -1;
	let entryIndex = -1;

	for (var i = 0; i < groups.length; i++){
		let {entries} = groups[i];

		for (var j = 0; j < entries.length; j++){
			//console.log("getSpecificEntryFromGroupsArray comparing entry id", entries[j][idkey], "to passed identry", identry, "with a result of", entries[j][idkey] == identry)
			if (entries[j][idkey] == identry){
				requestedEntry = entries[j];
				groupIndex = i;
				entryIndex = j;
			}
		}

	}

	return {requestedEntry, groupIndex, entryIndex};

}
import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import determineBMPStatus from '../../utils/determineBMPStatus'
import RequiredCorrectiveActions from '../../EntryComponents/RequiredCorrectiveActions';
import Document from '../../EntryComponents/generic/Document';
import NoActiveBMPError from '../../DisplayComponents/generic/NoActiveBMPError'
import generateLetterLabel from '../../utils/generateLetterLabel'

export default function BMPForm(props) {
    let {handleChange, handleBMPChange, cancelBMPFlow, completeReport, reportState, saveReportForLater} = props;
    let {sendingReport, loggedBMPs, activeBMPIndex} = reportState;

    let createActiveBMP = () => {
        console.log("BMPForm loggedBMPs:", loggedBMPs);
        console.log("BMPForm activeBMPIndex:", activeBMPIndex);
        return _.cloneDeep(loggedBMPs[activeBMPIndex])
    }

    let setActiveBMP = async (bmp) => {
        console.log("Calling setActiveBMP");
        await handleBMPChange(bmp, true)
        setActiveBMPDirect(bmp);
    }

    let setCorrected = (val) => {
        //handleChange('currentBMPCorrected', val);
        setCorrectedDirect(val)
    }

    let moveOn = async () => {

        let {correctionDocumentation, letterLabel} = activeBMP;

        let currentValidCorrections = true;
        if (!letterLabel){
            activeBMP.letterLabel = await generateLetterLabel(activeBMP, reportState, handleChange);
            await setActiveBMP(_.cloneDeep(activeBMP))
        }
   
        if (corrected){
            if (correctionDocumentation.description == "" && correctionDocumentation.photos.length == 0){
                currentValidCorrections = false;
            } else {
                currentValidCorrections = true;
            }
        }

        if ((corrected && currentValidCorrections) || !corrected){
            handleChange("phase", "LoggedEventsList");
        } else if (currentValidCorrections != validCorrections) {
            setValidCorrections(currentValidCorrections);
        }
    }
   
    let [activeBMP, setActiveBMPDirect] = useState(createActiveBMP());
    let [corrected, setCorrectedDirect] = useState(false);
    let [validCorrections, setValidCorrections] = useState(true);

    console.log("BMPEnterInitialCorrections Status:", activeBMP.status);

    if (_.isEmpty(activeBMP)) return <NoActiveBMPError />;

    else return(<div>

        <InspectionWrapper
          title="Log BMP Event"
          navButtons={[
            {
              className: "btn-secondary", 
              onClick: cancelBMPFlow,
              label: <><i className="fa fa-angle-left mr-1"></i> CANCEL</>
            },
            {
              className: "btn-primary",
              onClick: moveOn,
              label: <>SAVE BMP <i class="fa fa-angle-right ml-1"></i></>
            },

          ]}
          isBMPFlow={true}
        >

                <div class="col-12 mb-4 p-3 ml-2 border-bottom">
                    <p class="mb-1 fs-20"><i class="fa fa-cube px-2" aria-hidden="true"></i><strong>{activeBMP.type}: {activeBMP.name}</strong></p>
                </div>        


               <RequiredCorrectiveActions
                    initCas={activeBMP.cas}
                    callback={(passedCas) => {
                         let cloneBMP = _.cloneDeep(activeBMP);

                        if (passedCas == null){
                            cloneBMP.cas = null;
                            cloneBMP.status = "Documentation"

                        } else {
                            cloneBMP.cas = _.cloneDeep(passedCas);
                        }
                        
                        setActiveBMP(cloneBMP);
                    }}
               />

                <div class="col-12 mb-4 px-4">
                    <p class="mt-1 fs-17 mb-4"><strong>Corrected during Inspection?</strong> (optional) </p>
                    <button type="button" 
                        class="btn btn-round btn-outline-primary my-1" 
                        onClick={() => setCorrected(!corrected)}>
                            {corrected ? "NO, DONT DOCUMENT CORRECTION YET"
                            : "YES, DOCUMENT CORRECTION"}
                    </button>
                </div>

                {corrected ? 
                    <div class="border-primary col-12">
                        <Document
                            label="Corrective Action"
                            uniqueID="BMPFormCorrectiveAction"
                            initVal={activeBMP.correctionDocumentation}
                            valid = {validCorrections}
                            includeInitials={true}
                            onChange={(passedObj) => {
                                let cloneBMP = _.cloneDeep(activeBMP);
                                cloneBMP.correctionDocumentation = _.cloneDeep(passedObj);
                                setActiveBMP(cloneBMP);
                            }}
                        />
                    </div>
                : null}
              

        </InspectionWrapper>
    </div>)

}
    


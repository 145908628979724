import React, {Fragment} from 'react';

import BMPInspection from './BMPFlow/BMPInspection';
import BMPPlotLocation from './BMPFlow/BMPPlotLocation';
import BMPEnterConditions from './BMPFlow/BMPEnterConditions';
import BMPEnterInitialCorrections from './BMPFlow/BMPEnterInitialCorrections';
import BMPEventSummaryIncomplete from './BMPFlow/BMPEventSummaryIncomplete';
import BMPEventSummaryComplete from './BMPFlow/BMPEventSummaryComplete';
import BMPForm from './BMPFlow/BMPForm';
import LoggedEventsList from './BMPFlow/LoggedEventsList'

import InspectionInfo from './InspectionStartFlow/InspectionInfo';
import ProjectInfo from './InspectionStartFlow/ProjectInfo';
import ProjectPhase from './InspectionStartFlow/ProjectPhase';
import WeatherAndSoil from './InspectionStartFlow/WeatherAndSoil';

import BMPVerification from './InspectionEndFlow/BMPVerification';
import PlanReview from './InspectionEndFlow/PlanReview';
import StabilizationReview from './InspectionEndFlow/StabilizationReview';
import InspectionWrapUp from './InspectionEndFlow/InspectionWrapUp';
import InspectionSummary from './InspectionEndFlow/InspectionSummary';

//import _ from 'lodash';

let pages = new Map([
   
    /*BMP Flow*/

    ['BMPInspection',{
      name: "BMPInspection",
      JSX: (props) => <BMPInspection {... props} />
    }],

    ['BMPForm',{
      name: "BMPForm",
      JSX: (props) => <BMPForm {... props} />
    }],

    ['BMPEventSummaryIncomplete',{
      name: "BMPEventSummaryIncomplete",
      JSX: (props) => <BMPEventSummaryIncomplete {... props} />
    }],

    ['BMPEventSummaryComplete',{
      name: "BMPEventSummaryComplete",
      JSX: (props) => <BMPEventSummaryComplete {... props} />
    }],

    ['BMPPlotLocation',{
      name: "BMPPlotLocation",
      JSX: (props) => <BMPPlotLocation {... props} />
    }],


    ['BMPEnterConditions',{
      name: "BMPEnterConditions",
      JSX: (props) => <BMPEnterConditions {... props} />
    }],

    ['BMPEnterInitialCorrections',{
      name: "BMPEnterInitialCorrections",
      JSX: (props) => <BMPEnterInitialCorrections {... props} />
    }],

    ['LoggedEventsList',{
      name: "LoggedEventsList",
      JSX: (props) => <LoggedEventsList {... props} />
    }],

    ['InspectionInfo',{
      name: "InspectionInfo",
      JSX: (props) => <InspectionInfo {... props} />
    }],

    ['ProjectInfo',{
      name: "ProjectInfo",
      JSX: (props) => <ProjectInfo {... props} />
    }],

    ['ProjectPhase',{
      name: "ProjectPhase",
      JSX: (props) => <ProjectPhase {... props} />
    }],

    ['WeatherAndSoil',{
      name: "WeatherAndSoil",
      JSX: (props) => <WeatherAndSoil {... props} />
    }],

    ['BMPVerification', {
      name: "BMPVerification",
      JSX: (props) => <BMPVerification {...props} />
    }],


    ['PlanReview',{
      name: "PlanReview",
      JSX: (props) => <PlanReview {...props} />
    }],

    ['StabilizationReview',{
      name: "StabilizationReview",
      JSX: (props) => <StabilizationReview {...props} />
    }],

    ['InspectionWrapUp',{
      name: "InspectionWrapUp",
      JSX: (props) => <InspectionWrapUp {...props} />
    }],

    ['InspectionSummary',{
      name: "InspectionSummary",
      JSX: (props) => <InspectionSummary {...props} />
    }],


    /*For prev control */

    ['prev', {
      name: "prev",
      JSX: () => <></>
    }]

]);

export default pages;
import React, {Fragment, useState, useEffect} from 'react'

import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'

import BMPFlowNav from '../../NavComponents/BMPFlowNav'

import BMPLocationDisplay from '../../DisplayComponents/BMPLocationDisplay'
import LoggedBMPDisplay from '../../DisplayComponents/LoggedBMPDisplay';

export default function LoggedBMPs({reportState, handleChange, saveReportForLater}){

	let [loggedBMPs, setLoggedBMPs] = useState(_.cloneDeep(reportState.loggedBMPs));
	let [displayedLoggedBMPs, setDisplayedLoggedBMPS] = useState([]);
	let [filterStatus, setFilterStatus] = useState(null);

	useEffect(() => {

		if (filterStatus !== null){

		} else {

		}

	}, [filterStatus])

	return(<>

		<InspectionWrapper
	      title="BMP Verification"
	      navButtons={[
	        {
	          className: "btn-secondary", 
	          onClick: () => handleChange('phase', 'BMPInspection'), 
	          label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
	        },
	        {
	          className: "btn-outline-primary",
	          onClick: saveReportForLater,
	          label: "Save for Later"
	        },
	        {
	          className: "btn btn-primary",
	          onClick: () => handleChange("phase", (reportState.status == "setup" ? 'BMPVerification' : "InspectionSummary")),
	          label: <>Save &amp; Continue<i className="fa fa-angle-right ml-1"></i></>
	        }
	      ]}
	      isBMPFlow={true}
	      showBMPFlowNavTabs={true}
	      reportState={reportState}
	      handleChange={handleChange}
	    >



					<div className="col-12">
						<BMPLocationDisplay bmpList={loggedBMPs || []} reportState={reportState} />
					</div>

					{/*<div className="col-12 clearfix my-2 border-bottom">
						<div className="col-4 flexbox float-right">
						<label className="small strong text-brand">FILTER</label>
							<label className="lead-4 strong text-success"><i className="fa fa-check-square mr-2 text-success"></i></label>
							<label className="lead-4 strong text-warning"><i className="fa fa-exclamation-triangle mr-2 text-warning"></i></label>
							<label className="lead-4 strong text-danger"><i className="fa fa-clock-o mr-2 text-danger"></i></label>
						</div>
					</div>*/}

					<div className="col-12 mx-auto p-0 m-0 my-lg-4 pb-4 mb-4 pt-lg-4 pb-lg-7">

						{loggedBMPs.length ? loggedBMPs.map((bmp, index) => {
							return <LoggedBMPDisplay
								passedBMP={bmp}
								key={bmp.idaction}
								handleChange={handleChange}
								reportState={reportState}
							/>
						}) : <span className="text-small text-light ml-6">No BMPs logged yet</span>}

					</div>
		</InspectionWrapper>

  
  	</>);
 
}
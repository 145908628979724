import React, {Fragment} from 'react';
import PropTypes from 'prop-types'

function SummaryListDisplay({title, label, labelClassName, list, listKey, edit, showEdit, icon, isMobileOnly, useColumns}){
	return(<>
		<div className={"px-0 float-left " + (useColumns ? "col-12" : "col-12 col-md-6")}>
			<div class="card">
				<div class="card-body">

					<div class="col-12 mt-2 pb-3 px-0 clearfix">
						<div class="col-12 pl-1 mb-2 clearfix border-bottom">
							<p class="mt-4 mb-1 fs-20 float-left">{title}</p>
							{showEdit ? 
								<button className={"btn btn-xs btn-round btn-secondary fs-12 mb-3 ml-4 px-4 float-right" + (!isMobileOnly ? " mt-4" : "")} 
								onClick={edit}>
									Edit
								</button>
							: null}

						</div>

						<div className="col-12 ml-5">
							<ul className={"list-unstyled" + (useColumns ? " card-columns" : "")}>

								{list && list.length ? list.map((listItem, index) => {
									return <li className="strong" key={index}>
										{icon !== null ? <i className={icon + " strong pr-4"} aria-hidden="true"></i> : null}
										{listItem[listKey]}
									</li>
								}) : null}
								
							</ul>
						</div>
						<label className={labelClassName}><em>{label}</em></label>
					</div>
				</div>
			</div>
		</div>

	</>)
}

SummaryListDisplay.defaultProps = {
	labelClassName: "small ml-6",
	listKey: "name",
	useColumns: false,
	icon: null
}

export default SummaryListDisplay;
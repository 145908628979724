import React, {Fragment, useEffect, useState} from 'react'
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import BMPVerificationEntry from '../../EntryComponents/BMPVerificationEntry'
import createVerifiedBMPList from '../../utils/createVerifiedBMPList';
import _ from 'lodash';

/*First Page*/
export default function BMPVerification(props) {
	let {handleChange, reportState, saveReportForLater} = props;
	let {initialLoadFinished, flexdata} = reportState;

	useEffect(() => {
		if (reportState.initialLoadFinished){ //
			setVerifiedBMPList(createVerifiedBMPList(reportState));
		}
	}, [initialLoadFinished])

	useEffect(() => {
		if (reportState.initialLoadFinished){ //
			setVerifiedBMPList(createVerifiedBMPList(reportState));
		}
	}, [])

	let [verifiedBMPList, setVerifiedBMPListDirect] = useState([]);

	let setVerifiedBMPList = (passedVerifiedBMPList) => {
		let verifiedBMPList = _.cloneDeep(passedVerifiedBMPList);

		setVerifiedBMPListDirect(verifiedBMPList);
		handleChange("verifiedBMPList", verifiedBMPList);
	}

	return(<>

		<InspectionWrapper
	      title="BMP Verification"
	      navButtons={[
	        {
	          className: "btn-secondary", 
	          onClick: () => handleChange('phase', 'LoggedEventsList'), 
	          label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
	        },
	        {
	          className: "btn-outline-primary",
	          onClick: saveReportForLater,
	          label: "Save for Later"
	        },
	        {
	          className: "btn btn-primary",
	          onClick: () => handleChange("phase", "PlanReview"),
	          label: <>CONTINUE <i class="fa fa-angle-right ml-1"></i></>
	        }
	      ]}
	    >

			<div className="col-12 px-0 clearfix border-bottom pb-4">
				<p className="mt-4 mb-1 fs-17 float-left"><strong>Verify that remaining BMPs are in Good Condition:</strong></p>
			</div>        

			<div className="col-12 clearfix mb-7">

				{!_.isEmpty(verifiedBMPList) ? verifiedBMPList.map((bmp, index) => {
					return <BMPVerificationEntry
						passedBMP={bmp}
						callback={(newStatus) => {
							let cloneList = _.cloneDeep(verifiedBMPList);
							cloneList[index].status = newStatus;
							setVerifiedBMPList(cloneList);
						}}
						key={index}
					/>
				}) : null}


			</div>
				
		</InspectionWrapper>
          
	</>)
}
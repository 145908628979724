import splitDocumentationAndCorrectionBMPs from './splitDocumentationAndCorrectionBMPs'
import identifyIsDocumentationOnlyBMP from './identifyIsDocumentationOnlyBMP'
import _ from "lodash";

//Precondition: This function only behaves as intended on the newest BMP in the list
//Post-Condition: Must return type string, even if the label is numeric, otherwise labels will not render

export default async function generateLetterLabel(bmp, reportState, handleChange){

    let {loggedBMPs} = reportState;

    let isDocumentationOnly = identifyIsDocumentationOnlyBMP(bmp);
    let {correctionBMPs, documentationBMPs} = splitDocumentationAndCorrectionBMPs(loggedBMPs);

    console.log("Calling generateLetterLabel with bmp", bmp, " and loggedBMPs", loggedBMPs, " and correction BMPs", correctionBMPs, "and documentation bmps", documentationBMPs)


    let offset = -1;
    let useLetter = false;

    if (isDocumentationOnly){
        offset = documentationBMPs.length;
        useLetter = true;
    } else {
        offset = correctionBMPs.length;
    }

    let label = "--"

    console.log("Calling generateLetterLabel with offset", offset, "and useLetter", useLetter)

    if (useLetter){
        label = createNextLetterLabel(offset);
    }
	else {

        if (offset <= 99){
            label = offset + "";
        } else if (offset >= 100) {
            label = "00";
        }
    };

    console.log("generateLetterLabel Final label:", label);

    loggedBMPs[loggedBMPs.length-1].letterLabel = label;
    handleChange("loggedBMPs", _.cloneDeep(loggedBMPs));

    return label;
}

//Credit: https://www.geeksforgeeks.org/find-excel-column-name-given-number/
function createNextLetterLabel(labelNumber)
{
    // To store result
    let label = [];

    while (labelNumber > 0) {
        // Find remainder
        let rem = labelNumber % 26;

        // If remainder is 0, then a
        // 'Z' must be there in output
        if (rem == 0) {
            label.push("Z");
            labelNumber = Math.floor(labelNumber / 26) - 1;
        }
        else // If remainder is non-zero
        {
            label.push(String.fromCharCode((rem - 1) + 'A'.charCodeAt(0)));
            labelNumber = Math.floor(labelNumber / 26);
        }
    }

    // Reverse the string and print result
    return label.reverse().join("");
}
import React, {useState, useEffect, Fragment} from 'react';
import moment from 'moment';

export default function SummarySectionLineItem({label, value, type, emptyStateString}){

	let [displayVal, setDisplayVal] = useState("");

	let emptyState = <span className="text-light">{emptyStateString}</span>

	let calcDisplayVal = (value, type) => {

		if (!value){
			return emptyState;
		} else if (Array.isArray(value)){
			let newDisplayVal = [];
			for (var i = 0; i < value.length; i++){
				let calcedVal = calcDisplayVal(value[i], type[i]);
				if (calcedVal != emptyState){
					newDisplayVal.push(calcedVal);
				}
			}

			if (newDisplayVal.length == 0) newDisplayVal = emptyState;

			return newDisplayVal;
		} else if (type == "checkbox"){
			if (value.length == 0) return emptyState;
			else return value.replace(/,/g, ', ');
		} else if (type == "date"){
			if (typeof value !== "object"){
				return emptyState;
			} else {
				return (moment(value).format("MM-DD-YYYY"))
			}
		} else if (type == "phone"){
			if (value.length == 11){
		        const match = value
		            .replace(/\D+/g, '').replace(/^1/, '')
		            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
		        const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
		        const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
		        const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''    
		        return `${part1}${part2}${part3}`;
	        } else {
	        	return value;
	        }
		} else if (type == "time"){
			return moment(value).format("hh:mm a") + ""
		} else if (type == "datetime"){
			return moment(value).format("MM-DD-YYYY hh:mm a") + ""
		} else if (typeof value == "Object"){
			return "Major issue: Uncaught Object in SummarySectionLineItem, please let the appropriate contact know.";
		} else {
			return value;
		}

	}

	useEffect(() => {
		setDisplayVal(calcDisplayVal(value, type));
	}, [value, type])

	let displayParagraph = (displayVal) => {
		return <p class="strong fs-16 p-0 mb-4 lh-1">{displayVal}</p>
	}

	let renderDisplayVal = (displayVal) => {

		if (Array.isArray(displayVal)) {

			return displayVal.map((displayVal, key) => {
				if (key > 0 && displayVal == emptyState) return null;
				else return displayParagraph(displayVal);
			})
		} else {
			return displayParagraph(displayVal)
		}
			
	}

	return(<div class="col-12 float-left">
		<div class="col-11 p-0 m-0 float-left">
			{label !== null && label !== undefined ? <label class="text-light">{label}</label> : null}
			{renderDisplayVal(displayVal)}
		</div>
	</div>)

}
import React, {Fragment} from 'react';
import InspectionFooter from './InspectionFooter'
import BMPFlowNav from '../../NavComponents/BMPFlowNav'
import _ from 'lodash';
import {
  isMobileOnly
} from 'react-device-detect'

export default function InspectionWrapper({title, children, navButtons, handleChange, reportState, showBMPFlowNavTabs, isBMPFlow}){

  return <>
        <header class="section-header mb-0">
            <h2><strong>{title}</strong></h2>
        </header>

        <div class="section pt-0">
            <div class="container">
                {isBMPFlow ? <>

                    {showBMPFlowNavTabs ? 
                        <BMPFlowNav handleChange={handleChange} reportState={reportState} />
                    : null}

                    <div class="row">
                        {children}
                    </div>

                </> : 

                    <div class="row">

                        <div class="col-md-9 col-xl-8 mx-auto border-top mt-4 pt-4 mb-6 px-0">

                            <div class="col-12 px-0 clearfix">

                                {children}

                            </div>
                        </div>
                    </div>

                }

                <InspectionFooter
                    buttons={navButtons}
                />
            </div>
        </div>
    </>;

}
import React, {Fragment} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import RadioButtons from  '../../EntryComponents/generic/RadioButtons';
import Checkboxes from  '../../EntryComponents/generic/Checkboxes';
import EmployeeEntry from '../../EntryComponents/EmployeeEntry'
import DatePicker from 'react-datepicker' 

/*Fourth Page*/

export default function InspectionWrapUp(props){

    let {reportState, saveReportForLater, handleChange, getNumSelected, cancel} = props;
    let {flexdata} = reportState;

	return(<>

      <InspectionWrapper
        title="Inspection Wrap-Up"
        navButtons={[
          {
            className: "btn-secondary", 
            onClick: () => handleChange('phase', 'StabilizationReview'), 
            label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
          },
          {
            className: "btn-outline-primary",
            onClick: saveReportForLater,
            label: "Save for Later"
          },
          {
            className: "btn btn-primary",
            onClick: () => handleChange("phase", "InspectionSummary"),
            label: "View Summary"
          }
        ]}
      >

        <div class="col-12 mb-4 p-3 ml-2 clearfix border-bottom">
          <p class="mt-4 mb-1 fs-17 float-left"><strong>You're almost done!</strong></p>
        </div>   

        <div class="col-12 clearfix border-bottom ml-2 mb-5 pb-6 pr-4">

          <div class="col-12 mt-2 pb-2">   
            <div class="col-12 float-left px-0">
              <label class="text-primary">Inspection End Time</label>
              <div class="col-12 px-0 contrast float-left">
                <DatePicker
                className={"form-control strong fs-16"}
                dateFormat="MM/dd/yyyy hh:mm aa"
                selected={flexdata.inspectionend || null}
                onChange={ date => handleChange("inspectionend", date) }
                placeholderText="Date"
                showTimeSelect
                />
              </div>
            </div>
          </div>
        </div>


        <div class="col-12 clearfix ml-2 mb-7 pr-4">


          <EmployeeEntry
          reportState={reportState}
          handleChange={handleChange}
          getNumSelected={getNumSelected}
          />


        </div>

        </InspectionWrapper>

    </>)
}
import React, {Fragment} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import RadioButtons from  '../../EntryComponents/generic/RadioButtons';
import ReportCombobox from '../../EntryComponents/generic/ReportCombobox';
import {WorkGroupsService} from '../../../../../services'
import DatePicker from 'react-datepicker' 
import textLimits from '../../constants/textLimits'

/*Fourth Page*/

export default function ProjectPhase(props){

	let {reportState, saveReportForLater, handleChange, cancel} = props;
  let {flexdata} = reportState;

	return(<>
		<InspectionWrapper
      title="Project Phase"
      navButtons={[
        {
          className: "btn-secondary", 
          onClick: () => handleChange('phase', 'WeatherAndSoil'), 
          label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
        },
        {
          className: "btn-outline-primary",
          onClick: saveReportForLater,
          label: "Save for Later"
        },
        {
          className: "btn btn-primary",
          onClick: () => handleChange("phase", "BMPInspection"),
          label: "Tour Site"
        }
      ]}
    >


      <ReportCombobox
        title="Describe current phase of construction"
        maxLength={textLimits.textInputCharLimitStandard}
        value={flexdata.currentphase}
        onChange={(val) => handleChange("currentphase", val.name)}
        placeholder="Phase of Construction"
        dropdownValues={reportState.constructionPhases}
        onAddDropdownValue={async (newPhase) => {
          let cloneConstructionPhases = _.cloneDeep(reportState.constructionPhases);
          cloneConstructionPhases.push(newPhase);

          await WorkGroupsService.updateSitePhases({
            constructionPhases: cloneConstructionPhases, 
            idsite: reportState.idsite, 
            idworkgroup: reportState.workUnit.idworkgroup
          })
          handleChange("constructionPhases", cloneConstructionPhases);
        }}
        disabled={!reportState.idsite}
      />


      <div className="form-group contrast">
        <label className="text-primary">Scheduled Final Stabilization Date for Universal Soil Loss Equation ^</label>
        <div class="col-12 contrast float-left px-0">
					<DatePicker
						className={"form-control strong fs-16"}
						dateFormat="MM/dd/yyyy hh:mm aa"
						selected = {flexdata.stabilizationdate || null}
						onChange={ date => handleChange("stabilizationdate", date) }
						placeholderText="USLE Final Stabilization Date"
						showTimeSelect
		            />
				</div>
      </div>

      <RadioButtons
      	buttons={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}]}
      	title={<label className="text-primary">Project on Schedule? ^</label>}
      	onChange={(val) => handleChange('projectonschedule', val)}
      	defaultRadioVal={flexdata.projectonschedule}
      	wrapperClassName={"custom-controls-stacked mb-4"}
      	name={"projectonschedule"}
      	labelClassName={"custom-control-label strong fs-16"}
     />

  	 <RadioButtons
      	buttons={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}]}
      	title={<label className="text-primary">Is the erosion control plan accessible to operators?</label>}
      	onChange={(val) => handleChange('erosionplanaccessible', val)}
      	defaultRadioVal={flexdata.erosionplanaccessible}
      	wrapperClassName={"custom-controls-stacked mb-4"}
      	name="erosionplanaccessible"
      	labelClassName={"custom-control-label strong fs-16"}
  	 />

  	 <RadioButtons
      	buttons={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}]}
      	title={<label className="text-primary">Is the permit certificate posted where visible?</label>}
      	onChange={(val) => handleChange('permitcertificateposted', val)}
      	defaultRadioVal={flexdata.permitcertificateposted}
      	wrapperClassName={"custom-controls-stacked mb-4"}
      	name="permitcertificateposted"
      	labelClassName={"custom-control-label strong fs-16"}
  	 />

    </InspectionWrapper>

	</>)

}
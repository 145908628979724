import panels from '../constants/panels';
import {etchLibraryTerms} from "../utils";
import _ from 'lodash';

export function etGoToLibrary(passedSite, mode="practice") {

	console.log("etGoToLibrary with passedSite:", passedSite);

	let site = _.cloneDeep(passedSite)

	if (Array.isArray(site)){
		site = _.cloneDeep(site[0])
	}

	let terms = etchLibraryTerms(mode);

	console.log("etGoToLibrary site to find id and name:", site);

	let idsite = getSiteId(site);
	let siteName = getSiteName(site);

	if (idsite === -1){
		return "/app/inspection-reports";
	} else {

		//Two different localStorage items to prevent needing to use JSON.parse, preventing WSOD
		localStorage.setItem("etLibrarySite", idsite);
		localStorage.setItem("etLibrarySiteName", site.name);

		let targetPanel = panels.get('site-library-'+terms.mode.simplePlural);

		return targetPanel.path;
	}

}

let getSiteId = (site) => {

	let idsite = findHasValue(site, ["idsite", "idHaulcheckSite"])
	
	if (isNaN(idsite)){
		console.log("etGoToLibrary", "invalid idsite! passed value:", idsite);
		return -1;
	} else {
		return parseInt(idsite);
	}

	return null;
}

let getSiteName = (site) => {

	let siteName = findHasValue(site, ["name", "sitename", "siteName", "projectName"], "");

	if (typeof siteName !== "string"){
		return "";
	} else {
		return siteName;
	}

}

let findHasValue = (obj, validKeys, defaultValue = null) => {

	//console.log("etGoToLibrary findHasValue with obj", obj, "validKeys", validKeys, "defaultValue", defaultValue);

	if (typeof obj !== "object") return defaultValue;
	else {
		let objKeys = Object.keys(obj);
		let targetVal = defaultValue;

		//console.log("etGoToLibrary findHasValue objKeys:", objKeys);

		for (var i = 0; i < validKeys.length; i++){
			let key = validKeys[i];
			//console.log("etGoToLibrary findHasValue trying to find key:", key, "which is at index", objKeys.indexOf(key));

			if (objKeys.indexOf(key) !== -1){
				let val = obj[key];
				//console.log("etGoToLibrary findHasValue found a valid key:", key, "tied to val", val);

				if (val !== undefined && val !== defaultValue){
					//console.log("etGoToLibrary findHasValue val is good targetVal! with val", obj[key])
					targetVal = obj[key]
				}
			}
		}

		return targetVal;
	}
}
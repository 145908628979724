import _ from 'lodash';

export default function identifyIsDocumentationOnlyBMP(passedBMP){
	let bmp = _.cloneDeep(passedBMP);

	console.log("identifyIsDocumentationOnlyBMP with bmp", bmp);

	let toReturn = true;
	if (!_.isEmpty(bmp.cas) /*&& bmp.status != "Documentation"*/) toReturn = false;

	console.log("identifyIsDocumentationOnlyBMP found", toReturn);


	return toReturn;
}
import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
import {usePrevious} from '../../../../../utils'
import Combobox from '../../../../common/Combobox'


function ReportCombobox ({ 
	title,
	maxLength,
	value,
	onChange,
	dropdownValues,
	onAddDropdownValue,
	placeholder,
	inputClassName,
	titleClassName,
	wrapperClassName,
	disabled
}){


	return <div className={wrapperClassName}>
		<label className={titleClassName}>{title}</label>

		<Combobox
	        items={dropdownValues ? dropdownValues.map((name) => {return {name}}) : []}
	        value={value}
	        identifier="name"
	        onSelect={onChange}
	        className={inputClassName}
	        renderItem={item => {
	            return item.name
	        }}
	        normalStyle={{backgroundColor: 'white'}}
	        hoverStyle={{backgroundColor: '#ddd'}}
	        filterOptions={(phase, val, other) => {
	        	console.log("filterOptions phase:", phase);
	            return phase && phase.name ? phase.name.toLowerCase().startsWith(val) : false;
	        }}
	        showAddOption={true}
	        onAddElement={onAddDropdownValue}
	        placeholder={placeholder}
	        disabled={disabled}
	    />

	</div>

}

ReportCombobox.defaultProps = {
	inputClassName: "form-control strong fs-16", 
	titleClassName: "text-primary",
	wrapperClassName: "form-group contrast",
	title: null,
	disabled: false,
}

export default ReportCombobox;
import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
import {usePrevious} from '../../../../../utils'

function Checkboxes({
	name, 
	onChange,
	defaultCheckboxVal, 
	buttons, 
	title,
	divClassName, 
	includeDiv, 
	inputClassName, 
	labelClassName,
	wrapperClassName,
}){

	let [state, setState] = useState({checkboxVal: defaultCheckboxVal, buttons: _.cloneDeep(buttons)});
	let previousDefaultVal = usePrevious(defaultCheckboxVal)

	useEffect(() => {
		console.log("calling handleCheckboxChange from Checkboxes useEffect with value ", state.checkboxVal)
		onChange(state.checkboxVal)
	}, [state.checkboxVal])

	useEffect(() => {

		console.log("Checkboxes useEffect load defaultCheckboxVal:", defaultCheckboxVal, "and previousDefaultVal", previousDefaultVal)

		if (((previousDefaultVal === "" || previousDefaultVal === undefined) && defaultCheckboxVal !== "" && typeof defaultCheckboxVal == "string") || defaultCheckboxVal != previousDefaultVal){
			let hasMultipleVals = (defaultCheckboxVal.indexOf(",") !== -1)
			let cloneButtons = _.cloneDeep(state.buttons);
			let valArray = [];

			if (hasMultipleVals){
				valArray = defaultCheckboxVal.split(',');
			} else if (defaultCheckboxVal == "" || defaultCheckboxVal == undefined || defaultCheckboxVal == null){
				valArray = []
			} else {
				valArray = [defaultCheckboxVal]
			}

			console.log("Checkboxes useEffect valArray:", valArray);

			for (var i = 0; i < cloneButtons.length; i++){
				if (valArray.indexOf(cloneButtons[i].value) !== -1){
					cloneButtons[i].checked = true;
				} else {
					cloneButtons[i].checked = false;
				}
			}
			setState({buttons: cloneButtons, checkboxVal: valArray.join(',')})
		}
		
	}, [defaultCheckboxVal])

	let setCheckboxVal = (e, index) => {
		e.preventDefault();
		let cloneButtons = _.cloneDeep(state.buttons)

		cloneButtons[index] = toggleButtonChecked(cloneButtons[index]);		

		let newCheckboxVal = "";

		for (var i = 0; i < cloneButtons.length; i++){
			if (cloneButtons[i].checked) newCheckboxVal += cloneButtons[i].value + ","
		}

		newCheckboxVal = newCheckboxVal.slice(0, -1);

		setState({buttons: cloneButtons, checkboxVal: newCheckboxVal});
	}

	let toggleButtonChecked = (passedButton) => {
		let button = _.cloneDeep(passedButton);

		if (button.checked === undefined || button.checked === false){
			button.checked = true;
		} else if (button.checked === true) {
			button.checked = false;
		}

		return button;
	}


	return <div className={wrapperClassName}>
		{title}

		{buttons.map(({label, value, forceUseValue}, index) => {
			return <div className={divClassName} onClick={(e) => setCheckboxVal(e, index)} key={index}>
			    <input type="checkbox" className={inputClassName} name={name} value={forceUseValue == true ? value : label} checked={state.buttons[index].checked} id={name + "" + index} />
			    <label className={labelClassName} htmlFor={name + "" + index}>{label}</label>
	    	</div>
    	})}
	</div>

}

Checkboxes.defaultProps = {
	defaultCheckboxVal: "", 
	buttons: [{label: "Yes", value: "yes"}, {label: "No", value: "no"}, {label: "N/A", value: "na"}], 
	divClassName: "custom-control custom-checkbox", 
	includeDiv: true, 
	inputClassName: "custom-control-input", 
	labelClassName: "custom-control-label",
	wrapperClassName: "custom-controls-stacked",
	title: null
}

export default Checkboxes;
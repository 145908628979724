import React, {Component, useState, useEffect, useReducer, Fragment}  from 'react';
import SidebarCategory from './SidebarCategory';
import Collapse from 'react-bootstrap/Collapse';

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

export default function Sidebar({displayThis, panels, activePanel}) {

  const [showSidebar, setShowSidebar] = useState(false);

  let categoryObj = (icon) => {return new Object({
    displayTo: [],
    showIfNoWorkGroup: false,
    links: [],
    icon,
  })}

  const [sidebarCategories, setSidebarCategories] = useState(new Map([
    ["Reports", categoryObj("ti ti-clipboard")],
    ["Sites", categoryObj("ti ti-map-alt")],
    ["People", categoryObj("ti ti-id-badge")],
    ["Library", categoryObj("ti ti-agenda")]
  ]));

  //Create sidebar links from panels. UseEffect is used to ensure this process only happens once. Can be extended if panels ever need to change during runtime

  useEffect(() => {
    let lastMatchingPanelIndex = 0;

    panels.forEach((panel) => {
      let category = sidebarCategories.get(panel.menu);

      console.log("displayThis category displayTo before", category.displayTo)

      for (var i = 0; i < panel.displayTo.length; i++){
        let userToDisplayTo = panel.displayTo[i]
        if (category.displayTo.indexOf(userToDisplayTo) == -1){
          console.log("displayThis category pushing " + userToDisplayTo);
          category.displayTo.push(userToDisplayTo)
        } else {
          console.log("displayThis category not pushing " + userToDisplayTo);
        }
      }

      console.log("displayThis category displayTo after", category.displayTo);

      if (panel.showIfNoWorkGroup == true){
        category.showIfNoWorkGroup = true;
      } 

      category.links.push(panel);


      sidebarCategories.set(panel.menu, category);

    });

    setSidebarCategories(sidebarCategories);
  }, [panels])

  //Display the sidebar
	return(<>
    <div className="mt-3 ml-5" id="sidebar-control" style={activePanel.displaySidebar ? null : {display:'none'}}><i onClick={() => setShowSidebar(true)} className="ti ti-menu lead-4 cursor" aria-hidden="true"></i></div>

    <div className="sidebar-toggler" style={ showSidebar && activePanel.displaySidebar && isMobile ? 
        {/*'backgroundColor': '#ff00ff',*/ height: '100vh', width: '100vw', position: 'absolute', top: '0', left: '0'}
         : {'display': 'none'} } onClick={() => setShowSidebar(false)}>
    </div>

		<div id="mySidenav" className="sidenav bg-gray" style={showSidebar == true && activePanel.displaySidebar ? {right: '250px', left: '0px', zIndex: 600} : {right: '0px', left: '-250px', zIndex: 600}}>
      <a href="#" className="text-light fw-400 text-right pt-4 pr-4 pb-6" onClick={() => setShowSidebar(false)}><i className="ti ti-arrow-circle-left lead-4" aria-hidden="true"></i></a>
      <ul className="list-unstyled">
        {Array.from(sidebarCategories).map((category, key) => {

          if (category[1].links && category[1].links.length && displayThis(category[1])){
            return <><SidebarCategory
              categoryName={category[0]}
              icon={category[1].icon}
              links={category[1].links}
              displayThis={displayThis}
              activePanel={activePanel}
              key={key}
            /></>
          } else return null

        })}
      </ul>
    </div>

     

	</>)
}

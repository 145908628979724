import _ from 'lodash';
import { url } from '../../../../constants'

export default function getBMPImage (bmp) {

	let {correctionDocumentation, conditionDocumentation} = bmp;
	let img = "../img/thumb/0.png";

	if (!_.isEmpty(correctionDocumentation.photos)){
		img = url.backend + "/images/practiceactions/" + correctionDocumentation.photos[0]
	} else if (!_.isEmpty(conditionDocumentation.photos)){
		img = url.backend + "/images/practiceactions/" + conditionDocumentation.photos[0]
	}

	return img;
}
import React, {Fragment} from 'react'
import _ from 'lodash'
import {
    PracticeActionsService,
    //ApiService,
    //ReportsService,
    //WorkGroupsService
} from '../../../../services'

import setActiveBMP from '../utils/setActiveBMP';

export default function BMPEntryInspection({reportState, handleChange, handleBMPChange}){

	let {groupsList, activeEntryIndex, activeGroupIndex, idsite} = reportState;

	let goToBMPForm = (selectedGroupIndex, selectedEntryIndex) => {
        setActiveBMP(handleChange, reportState, false, selectedGroupIndex, selectedEntryIndex);
        handleChange("phase", "BMPPlotLocation");
    }

	return<>

        <div class="col-md-10 col-xl-9 mx-auto mb-6 px-0">

            <div class="col-12 px-0 clearfix border-bottom pb-2 mb-4">
                <p class="mt-4 mb-1 fs-17 float-left"><i class="fa fa-cube px-2" aria-hidden="true"></i><strong>Log instances of BMPs:</strong></p>
            </div> 

            <div class="col-12 clearfix px-0">
            {groupsList.map((group, groupIndex) => {
                return(<div class="col-md-6 float-left mb-4" key={groupIndex}>
                        <p class="fs-16 mt-4 pb-2 mb-4 border-bottom"><strong>{group.name}</strong></p>
                        <ul class="list-unstyled">
                            {group.entries.map((entry, entryIndex) => {
                                return <li key={entryIndex} class="strong py-1 py-md-2">
                                    <a class="fs-20 fw-700" href="#" onClick={() => goToBMPForm(groupIndex, entryIndex)}>
                                        <i class="fa fa-plus-circle mr-2"></i>
                                        <span className="text-dark fs-18">{entry.name}</span>
                                    </a>
                                </li>
                            })}
                             
                        </ul>
                    </div>)
                })}
            </div> 

        </div>  

	</>

}
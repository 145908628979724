import React, {Fragment} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import RadioButtons from  '../../EntryComponents/generic/RadioButtons';
import Checkboxes from  '../../EntryComponents/generic/Checkboxes';
import EmployeeEntry from '../../EntryComponents/EmployeeEntry'

/*Third Page*/

export default function StabilizationReview(props){

    let {reportState, saveReportForLater, handleChange, cancel} = props;
    let {flexdata} = reportState;

	return(<>

        <InspectionWrapper
          title="Stabilization Review"
          navButtons={[
            {
              className: "btn-secondary", 
              onClick: () => handleChange('phase', 'PlanReview'), 
              label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
            },
            {
              className: "btn-outline-primary",
              onClick: saveReportForLater,
              label: "Save for Later"
            },
            {
              className: "btn btn-primary",
              onClick: () => handleChange("phase", "InspectionWrapUp"),
              label: <>CONTINUE <i class="fa fa-angle-right ml-1"></i></>
            }
          ]}
        >

        <RadioButtons
            name="constructionstabilized"
            defaultRadioVal={flexdata.constructionstabilized}
            buttons={[
                {label: "Yes", value: "yes"},
                {label: "No", value: "no"},
                {label: "Not Applicable", value: "na"},
            ]}
            onChange={(val) => handleChange("constructionstabilized", val)}

            wrapperClassName="form-group contrast clearfix mr-6 mb-5"
            title={<label class="strong fs-16">Are all areas where construction has temporarily ceased (and will not resume for more than 2 weeks) temporarily stabilized?</label>}
            divClassName="custom-control custom-radio pt-2 pr-4"
            labelClassName="custom-control-label fs-14"

        />

        <RadioButtons
            name="areasstabilized"
            defaultRadioVal={flexdata.areasstabilized}
            buttons={[
                {label: "Yes", value: "yes"},
                {label: "No", value: "no"},
                {label: "Not Applicable", value: "na"},
            ]}
            onChange={(val) => handleChange("areasstabilized", val)}

            wrapperClassName="form-group contrast clearfix mr-6 mb-5"
            title={<label class="strong fs-16">Are all areas at final grade permanently vegetated or stabilized with other treatments?</label>}
            divClassName="custom-control custom-radio pt-2 pr-4"
            labelClassName="custom-control-label fs-14"

        />

        <RadioButtons
            name="sedimentcontrolsremoved"
            defaultRadioVal={flexdata.sedimentcontrolsremoved}
            buttons={[
                {label: "Yes", value: "yes"},
                {label: "No", value: "no"},
                {label: "Not Applicable", value: "na"},
            ]}
            onChange={(val) => handleChange("sedimentcontrolsremoved", val)}

            wrapperClassName="form-group contrast clearfix mr-6 mb-5"
            title={<label class="strong fs-16">Have temporary sediment controls been removed in areas of the site that meet the permit definition of 'final stabilization'?</label>}
            divClassName="custom-control custom-radio pt-2 pr-4"
            labelClassName="custom-control-label fs-14"

        />


        </InspectionWrapper>
                

    </>)
}
import React, {useState, useEffect} from 'react';
import _ from 'lodash'
import {
    LibraryService
} from '../../../services'

export default function CopyMoveEntry({mode, terms, groups, identry, oldGroup, callback, siteData}){

    let [newGroup, setNewGroup] = useState(_.cloneDeep(oldGroup));

    useEffect(() => {
        setNewGroup(oldGroup)
    }, [oldGroup])

    let submit = async () => {
        await LibraryService.copyMoveEntry({
            identry, mode, newGroup, idsite: (siteData.isSiteLibrary ? siteData.idsite : null)
        });
        if (callback) callback();
        let close = document.getElementById("copyMoveEntry-close");
        if (close) close.click();
        let viewClose = document.getElementById("close-view-modal-button");
        if (viewClose) viewClose.click();
    }

    return <div class="modal fade show" style={{zIndex: '2000', backgroundColor: "rgba(84,84,84,0.3)"}} id="copyMoveEntry" tabindex="-1" role="dialog" aria-labelledby="edit-practice" aria-modal="true">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <p class="modal-title strong fs-17" id="add-practice">Copy/Move {terms.mode.display} To Another Group</p>
                    <button id="copyMoveEntry-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div class="modal-body">

                    <div class="col-12 pl-0 mb-3 ml-4 clearfix">
                        <i class="fa fa-cubes float-left mt-4" aria-hidden="true"></i>
                        <div class="col-11 p-0 pr-6 mr-2 mb-4 ml-4 float-left">
                            <div class="contrast">
                                <select class="form-control p-0" onChange={(e) => setNewGroup(e.target.value)} value={newGroup}>
                                {
                                    !_.isEmpty(groups) ? groups.map((group, key) => {
                                        return <option value={group.name} key={key} disabled={group.name == oldGroup}>{group.name}</option>
                                    }) : null
                                }
                                </select>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" onClick={submit} disabled={newGroup == oldGroup}>Save changes</button>
                </div>
            </div>
        </div>
    </div>
}
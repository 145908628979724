import React, {Fragment, useEffect} from 'react';
import BaseGuideModal from './BaseGuideModal';

export default function EtUserCheck ({seen, markAsSeen}) {

  useEffect(() => {
   if (!seen) markAsSeen();
 }, [])
  
  return null;
}
import React, {Fragment} from 'react'
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import BMPFlowNav from '../../NavComponents/BMPFlowNav'
import BMPEntryInspection from '../../EntryComponents/BMPEntryInspection'

export default function BMPInspection({reportState, handleChange, saveReportForLater, handleBMPChange}){

	return(<>

		<InspectionWrapper
	      title="BMP Inspection"
	      navButtons={[
	        {
	          className: "btn-secondary", 
	          onClick: () => handleChange('phase', (reportState.status == "setup" ? 'ProjectPhase' : "InspectionSummary")), 
	          label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
	        },
	        {
	          className: "btn-outline-primary",
	          onClick: saveReportForLater,
	          label: "Save for Later"
	        },
	        {
	          className: "btn btn-primary",
	          onClick: () => handleChange("phase", "LoggedEventsList"),
	          label: <>REVIEW BMPs <i class="fa fa-angle-right ml-1"></i></>
	        }
	      ]}
	      isBMPFlow={true}
	      showBMPFlowNavTabs={true}
	      reportState={reportState}
	      handleChange={handleChange}
	    >
  
				<BMPEntryInspection
					reportState={reportState}
					handleChange={handleChange}
					handleBMPChange={handleBMPChange}
				/>

		</InspectionWrapper>
  
	</>)
}
import React from 'react';
import _ from "lodash";


const markerColors = {	
	"Complete": "#3cd458",
	"Overdue": "#f22236",
	"Incomplete": "#ffba00",
	"Documentation": "#3c96ff",
}


const functions = {

	createMapMarkerCircle: (status, letterLabel) => {
		let svg = '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="29" viewBox="0 0 25 29">'+

		'<path d="M9.28,22.94a11.26,11.26,0,1,1,6.44,0L12.5,28.51Z" style="fill:'+markerColors[status]+'"/>'+
	  	'<path d="M12.5,1.39a10.76,10.76,0,0,1,2.89,21.12L14,24.91l-1.5,2.6L11,24.91l-1.39-2.4A10.76,10.76,0,0,1,12.5,1.39m0-1a11.76,11.76,0,0,0-3.56,23l1.19,2.05,1.5,2.6.87,1.5.87-1.5,1.5-2.6,1.19-2.05A11.76,11.76,0,0,0,12.5.39Z" style="fill:#fff"/>'
	  
		if (letterLabel.length == 2){
			svg += '<text transform="translate(4.5 16)" font-size="12" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700" letter-spacing="-0.04em">'+letterLabel+'</text>'
		} else if (letterLabel.length == 1){
			svg += '<text transform="translate(8.5 16)" font-size="12" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700" letter-spacing="-0.04em">'+letterLabel+'</text>'
		}

		svg += '</svg>';
		return svg;
	},

	createMapMarkerSquare: (status, letterLabel) => {
		let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 22.77 29.01">`+
			`<polygon style="fill:${markerColors[status]}" points="8.13 22.12 0.92 22.12 0.92 1.19 21.85 1.19 21.85 22.12 14.64 22.12 11.39 27.75 8.13 22.12"/>`
			
		if (letterLabel.length == 2){
			svg += '<text transform="translate(4.5 16)" font-size="12" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700" letter-spacing="-0.04em">'+letterLabel+'</text>'
		} else if (letterLabel.length == 1){
			svg += '<text transform="translate(8.5 16)" font-size="12" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700" letter-spacing="-0.04em">'+letterLabel+'</text>'
		}

		svg += '</svg>';
		return svg;
	},

	createListMarkerCircle: (status, letterLabel) => {
	    return <svg xmlns="http://www.w3.org/2000/svg" className="float-left mt-1 mr-2" width="25" height="25" viewBox="0 0 25 25">
				<circle cx="12" cy="12" r="10" fill={markerColors[status]}/>

			{(letterLabel.length == 2) ?
				<text transform="translate(3.5 15.5)" font-size="12" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700" letter-spacing="-0.04em">{letterLabel}</text>
			: (letterLabel.length == 1) ?
				<text transform="translate(7.5 15.5)" font-size="12" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700">{letterLabel}</text>
			: null
			}
		
		</svg>;
	},

	createListMarkerSquare: (status, letterLabel) => {
		return <svg xmlns="http://www.w3.org/2000/svg" className="float-left mt-1 mr-2" width="25" height="25" viewBox="0 0 22.77 29.01">
			<polygon fill={markerColors[status]} points="8.13 22.12 0.92 22.12 0.92 1.19 21.85 1.19 21.85 22.12 14.64 22.12 11.39 27.75 8.13 22.12" />

			{(letterLabel.length == 2) ?
				<text transform="translate(3.5 15.5)" font-size="12" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700" letter-spacing="-0.04em">{letterLabel}</text>
			: (letterLabel.length == 1) ?
				<text transform="translate(7.5 15.5)" font-size="12" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700">{letterLabel}</text>
			: null
			}

		</svg>
	},
}

export default function getBMPMarker(letterLabel, status, context){
	console.log("getBMPMarker typeof letterLabel", letterLabel, typeof letterLabel)

	if (letterLabel.length > 2) letterLabel = letterLabel.substring(0, 2);
	if (letterLabel.length == 0) letterLabel = "-"

	let shape = "circle";
	if (status == "Documentation") shape = "square";

	let createMarkerFunc = "create" + _.capitalize(context) + "Marker" + _.capitalize(shape)

	return functions[createMarkerFunc](status, letterLabel)
}

import { url, earthidealAPIURL } from '../constants'
import JwtDecode from 'jwt-decode'

export const AllSitesService = {
  forETImport
}

async function forETImport() {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const resp = await fetch(`${url.backend}/allSites/forETImport/${username}`).then(handleResponse)
  return resp.sitesListForImport
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

import { url } from '../constants'
import JwtDecode from 'jwt-decode'

export const LibraryService = {
  getWorkgroupHasLibrary,
  addGroupsToLibrary,
  addIndividualEntry,
  editEntry,
  deleteEntry,
  getLibrary,
  getActionsForPracticeList,
  getOppositeRelations,
  deletePhoto,
  deleteGroup,
  renameGroup,
  resetSiteLibrary,
  copyMoveEntry
}

async function getWorkgroupHasLibrary(idworkgroup){
  const resp = await fetch(`${url.backend}/library/getWorkgroupHasLibrary/${idworkgroup}`).then(handleResponse)
  return resp.hasLibrary
}

async function getLibrary(idworkgroup, mode, getOppositeRelations = 0, idsite=null){

  if (!idworkgroup){
      let workUnit = JSON.parse(localStorage.getItem('activeWorkGroup'));
      idworkgroup = workUnit.idworkgroup;
  }

  if (getOppositeRelations){
    getOppositeRelations = 1;
  } else {
    getOppositeRelations = 0;
  }

  const resp = await fetch(`${url.backend}/library/getLibrary/${idworkgroup}/${mode}/${getOppositeRelations}/${idsite}`).then(handleResponse)

  console.log("getLibrary resp:", resp)

  if (getOppositeRelations){
    for (var i = 0; i < resp.groups.length; i++){
      for (var j = 0; j < resp.groups[i].entries.length; j++){
        console.log("getLibrary resp 2:", resp.groups[i].entries[j])
        resp.groups[i].entries[j].conditionDocumentation.date = new Date(resp.groups[i].entries[j].conditionDocumentation.date);
        resp.groups[i].entries[j].correctionDocumentation.date = new Date(resp.groups[i].entries[j].correctionDocumentation.date);
      }
    }
  }

  return resp.groups
}

async function addGroupsToLibrary(data){
  const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = unit.idworkgroup;
  
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/addGroupsToLibrary/`, requestOptions)
        .then (handleResponse);
}

async function addIndividualEntry(data){
  const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = unit.idworkgroup;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/addIndividualEntry/`, requestOptions)
        .then (handleResponse);
}

async function editEntry(data){
  const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = unit.idworkgroup;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/editEntry/`, requestOptions)
        .then (handleResponse);
}

async function deleteEntry(data){
  const unit = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = unit.idworkgroup;

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/deleteEntry/`, requestOptions)
        .then (handleResponse);
}

async function getActionsForPracticeList(practiceList){
  if (practiceList.length == 0) practiceList="0"
  const resp = await fetch(`${url.backend}/library/getActionsForPracticeList/${practiceList}`).then(handleResponse)
  return resp.groups
}

async function getOppositeRelations(identry, mode){
  const resp = await fetch(`${url.backend}/library/getOppositeRelations/${identry}/${mode}`).then(handleResponse)
  return resp.oppositeRelations
}

async function deletePhoto(data){
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

  return await fetch(`${url.backend}/library/deletePhoto`, requestOptions).then(handleResponse);
}


async function deleteGroup(data){
  let {idworkgroup} = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = idworkgroup;

  console.log("deleteGroup data:", data);

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/deleteGroup/`, requestOptions)
        .then (handleResponse);
}

async function renameGroup(data){
  let {idworkgroup} = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = idworkgroup;

  console.log("renameGroup data:", data);

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/renameGroup/`, requestOptions)
        .then (handleResponse);
}

async function resetSiteLibrary(data){
  let {idworkgroup} = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = idworkgroup;

  console.log("resetSiteLibrary data:", data);

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/resetSiteLibrary/`, requestOptions)
        .then (handleResponse);
}

async function copyMoveEntry(data){
  let {idworkgroup} = JSON.parse(localStorage.getItem('activeWorkGroup'));
  data.idworkgroup = idworkgroup;

  console.log("copyMoveEntry data:", data);

  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch (`${url.backend}/library/copyMoveEntry/`, requestOptions)
        .then (handleResponse);
}



function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

import React, {Fragment} from 'react'
import InspectionMapCore from '../HereMapComponents/InspectionMapCore'
import _ from 'lodash';

export default function BMPLocationDisplay({reportState, bmpList, getMapImageCallback, capture, mapLoadedCallback, mapUnloadedCallback}){

	return(<>

		<section class="section" style={{minHeight: '400px'}}>

			<InspectionMapCore
				defaultCenter={reportState.mapCenter}
				defaultZoom={reportState.mapZoom}
				displayMode={true}
				bmpList={bmpList}
				reportState={reportState}
				getMapImageCallback={getMapImageCallback}
				capture={capture}
				mapLoadedCallback={mapLoadedCallback}
				mapUnloadedCallback={mapUnloadedCallback}
			/>

        </section>

	</>)

}

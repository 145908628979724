import React, {Component, Fragment} from 'react'
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import H from "@here/maps-api-for-javascript";
import {url, earthidealAPIURL} from '../../constants';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import {
    WorkGroupsService
} from '../../services'
import {etGoToLibrary} from "../../utils"

import HereMapsSiteWidget from './HereMapsSiteWidget';


export default class NewSite extends Component {

	 constructor(props){
        super(props);

        this.minNameLength = 4;
  		this.maxNameLength = 140;

        this.state = {
        	sitename: "",
            markerPosition: {lat: 43.03501925228261, lng: -87.90833392173009},
            zoomLevel: 13,
            position: null,
            success: false,
            siteNameInvalid: false,
            verifyZoomLevel: false,
            zoomLevelVerified: false,
        }
    }

    getZoomLevelIsLogical = (level) => {
    	console.log("submitNewSite checking zoom level ", level, "level is illogical?", level > 17)
    	return level > 17
    }


    submitNewSite = async (goToLibrary) => {
    	const userJWT = localStorage.getItem('token')
		const userDecoded = jwtDecode(userJWT)
		const username = userDecoded ? userDecoded['cognito:username'] : null

		console.log("Calling submitNewSite")
		

    	let {sitename, markerPosition, position, zoomLevel, zoomLevelVerified} = this.state;

    	if (sitename.length < this.minNameLength || sitename.length > this.maxNameLength){
    		console.log("submitNewSite Site name is invalid")
    		this.setState({siteNameInvalid: true})
    	} else if (!this.getZoomLevelIsLogical(zoomLevel) && !zoomLevelVerified) {
    		this.setState({verifyZoomLevel: true, siteNameInvalid: false});
    		localStorage.setItem('goToLibrary', goToLibrary);
    		console.log("submitNewSite zoom level is illogical")
    	} else {
    		console.log("submitNewSite submitting new site")
    		this.setState({siteNameInvalid: false, verifyZoomLevel: false});

    		if (goToLibrary === undefined) goToLibrary = localStorage.getItem('goToLibrary')

    		console.log("submitNewSite goToLibrary val:", goToLibrary)

	    	let {unit} = this.props;
	        ajax.post(earthidealAPIURL + "/sites/add")
	            .send({
	                formValues: {
	                    nameProject: sitename
	                },
	                geometry: markerPosition,
	                userId: username,
	                appOfOrigin: "earthtight"
	            }).then(async (res) => {
	                let idsite = res.body.haulSiteId;
	                let sitesList = await WorkGroupsService.setSiteVisibility({idsite, idworkgroup: unit.idworkgroup, visibility: 1, zoomLevel, position});
	                this.setState({sitesList, success: true}, () => {
	                	console.log("newSite redirect site stuff:", res.body)

	                	let path = etGoToLibrary(res.body.newSite);
	                	console.log("newSite redirect final path:", path)
	                	//alert("pausing execution")

	                	if (path){
	                		this.props.history.push(path)
	                	} else {
	                		console.log("NewSite submit goToLibrary failed, value of path:", path);
	                		//alert("Error redirecting to library!")
	                	}
	                })
	            }).catch(error => {
	                console.log("Site create error:", error);
	               // alert("Error creating site!")
	            });
        }
    }

    render = () => {

    	let {siteNameInvalid} = this.state;
    	let siteNameTooShort = (this.state.sitename.length < this.minNameLength);
    	let siteNameTooLong = (this.state.sitename.length > this.maxNameLength);

    	return(<>
     		<header class="section-header mb-0">
            	<h2><strong>New Site</strong></h2>
            </header>

            <div class="row">

				<div class="col-10 border-primary px-6 py-3 mx-auto mt-3">

					<div id="workunitsites">
						<div class="card card-body">
							<div class="row m-0">
								<div class="col-12 col-lg-11 mt-5">
									<div class="form-group row gap-y">

										<div class="col-lg-7 p-0 mb-6">
											<div class="">
												<label class="strong p-0 m-0">Name your new site:</label>
												<input class={"form-control p-2 " + (siteNameInvalid && ((siteNameTooShort || siteNameTooLong)) ? "is-invalid" : "mb-4")} type="text" placeholder="Project Name or Location"
													value={this.state.sitename}
													onChange={(e) => this.setState({sitename: e.target.value})} />
													{siteNameInvalid ? 
													siteNameTooLong ?
														<span className="text-danger mb-4">New site name is too long</span>
													: siteNameTooShort ?
														<span className="text-danger mb-4">New site name is too short</span>
													: null
												: null}
												<div class="alert alert-info my-2" role="alert">
										 			<strong>Please frame your site in this map the way you want to see it in the reports!</strong><p className="mb-0">Make sure you're zoomed in and have your site centered.</p>
												</div>
												<div style={{height: '300px'}} id="map-wrapper">
													<HereMapsSiteWidget
														callbackMarker={(markerPosition) => {
															this.setState({markerPosition});
														}}
														callbackZoom={(zoomLevel, position) => {
															this.setState({zoomLevel, position});
														}}
													/>
								                </div>
											</div>
										</div>

									</div>
									{this.state.verifyZoomLevel && !this.getZoomLevelIsLogical(this.state.zoomLevel) ? 
										<div className="col-7 alert alert-warning mt-6 mr-auto" role="alert">
				                            Are you sure this is how you want the map to display in your inspections? You may want to zoom in further.
				                            <button 
				                            	type="button" 
				                            	className="btn btn-warning mt-2" 
				                            	onClick={() => {
				                            		this.setState({zoomLevelVerified: true}, this.submitNewSite)
				                            	}}>
				                            	Yes, I'm sure
				                            </button>
				                        </div> 
			                        : <>

    									<button className="btn btn-sm btn-outline-primary float-right mt-8 mt-md-2 ml-3" disabled={this.state.success} onClick={() => this.submitNewSite(true)}>SAVE & CUSTOMIZE BMPS</button>
										<button className="btn btn-sm btn-primary float-right mt-8 mt-md-2" disabled={this.state.success} onClick={() => this.submitNewSite(false)}>SAVE SITE</button>

									</>}
								</div>

								{this.state.success ?  <div className="col-11 alert alert-success mt-6 mr-auto" role="alert">
		                            Your site has been added!
		                        </div> : null}

							</div>

						</div>
					</div>

				</div>

        	</div>
    	</>)
	}
  

}

import {statusDisplay} from "../../DisplayComponents/StatusDisplay"
import getBMPImage from "../../utils/getBMPImage"
import {handleFileUpload, buildElementsFromJSON} from '../../../../../utils'

export default function bmpBubbleContent (bmp, reportState) {

	console.log("Calling bmpBubbleContent with bmp", bmp.bmpIndex, " and reportState ", reportState.status);

	let status = statusDisplay({
		status: bmp.status,
		reportStatus: reportState.status,
		onClick: bmp.bmpIndex
	})

	let content = {
		elementType: "div",
		attributes: {
			"class": "media-body"
		},
		children: [

			{
				elementType: "img",
				attributes: {
					src: getBMPImage(bmp)
				}
			},

			{
				elementType: "span",
				children: bmp.name,
				attributes: {
					class: "strong fs-16"
				}
			},
			status[0],
			status[1]
		]
	};


	return buildElementsFromJSON(content);

}
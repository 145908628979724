import React, {Component, useState, useEffect, useLayoutEffect, Fragment} from 'react'
import SuccessPopup from '../../common/Popups'
import Event from "../../Event"
import ViewEntryModal from '../Modals/ViewEntryModal';
import EditOrAddEntry from '../Modals/EditOrAddEntry';
import CopyMoveEntry from '../Modals/CopyMoveEntry';
import EditGroupName from '../Modals/EditGroupName';
import {
    LibraryService
} from '../../../services'
import {Link} from 'react-router-dom'
import panels from '../../../constants/panels';
import DisplayGroup from './DisplayGroup';
import _ from 'lodash';

import {
    etchLibraryTerms,
    etLoadSiteLibraryData,
    getSpecificEntryFromGroupsArray
} from '../../../utils'

export default function ViewLibrary ({unit, mode, type, isMasterLibrary, isSiteLibrary}) {

    let [entryUpdate, setEntryUpdateDirect] = useState({activeEntry: {identry: 0}, typeOfUpdate: "", trigger: false});
    let [groupUpdate, setGroupUpdateDirect] = useState({activeGroup: "", typeOfUpdate: "", trigger: false});
    let [deleteGroupTrigger, setDeleteGroupTrigger] = useState(false);
    let [deleteEntryTrigger, setDeleteEntryTrigger] = useState(false);
    let [editOrAddEntryTrigger, setEditOrAddEntryTrigger] = useState(false);
    let [resetSiteLibraryTrigger, setResetSiteLibraryTrigger] = useState(false);
    let [loading, setLoading] = useState(true);
    let [groups, setGroups] = useState([]);
    let [modalOpen, setModalOpen] = useState(false);

    //These variables are used for site libraries only
    let [siteData] = useState(etLoadSiteLibraryData({isMasterLibrary, isSiteLibrary}));



    const terms = etchLibraryTerms(mode);

    useLayoutEffect(() => {
    	refreshGroups();
    }, [unit, (unit !== null ? unit.idworkgroup : undefined)]);

    useEffect(() => {

        console.log("DeleteEntry", "ViewLibrary update triggered with entryUpdate:", entryUpdate, "and groupUpdate,", groupUpdate)

        if (entryUpdate.trigger = true){
            let {activeEntry, typeOfUpdate} = entryUpdate;

            if (typeOfUpdate == "deleteEntry"){
                setDeleteEntryTrigger(true);
            } else if (typeOfUpdate == "editEntry"){
                setEditOrAddEntryTrigger(true);
            }

            resetEntryTrigger();
        } 

        if (groupUpdate.trigger = true){
            let {activeGroup, typeOfUpdate} = groupUpdate;

            if (typeOfUpdate == "addEntry"){
                setEditOrAddEntryTrigger(true);
            } else if (typeOfUpdate == "delete"){
                setDeleteGroupTrigger(true);
            }

            resetGroupTrigger();
        }

    }, [entryUpdate.trigger, groupUpdate.trigger])

    const resetEntryTrigger = () => setEntryUpdateDirect((prev) => {return {...prev, trigger: false}});
    const resetGroupTrigger = () => setGroupUpdateDirect((prev) => {return {...prev, trigger: false}});
    const setEntryUpdate = (updateData) => { setEntryUpdateDirect(() => { console.log("entry update:",  {...updateData, trigger: true}); return {...updateData, trigger: true} })};
    const setGroupUpdate = (updateData) => { setGroupUpdateDirect(() => { return {...updateData, trigger: true} })};


    const refreshGroups = async (suppressLoading, passedIdEntry = null) => {
        let {idsite, isSiteLibrary} = siteData;

        if (!suppressLoading && !loading) {setLoading(true)}
        let fetchedGroups = [];
        if (unit !== null) {fetchedGroups = await LibraryService.getLibrary(unit.idworkgroup, mode, 0, (isSiteLibrary ? idsite : null))}

        console.log("refreshGroups groups:", fetchedGroups)

        setGroups(fetchedGroups);
        setLoading(false);
        resetEntryTrigger();
        resetGroupTrigger();

        if (entryUpdate.activeEntry && entryUpdate.activeEntry[terms.mode.identry]){
            let identry = entryUpdate.activeEntry[terms.mode.identry];

            if (passedIdEntry !== null){
                identry = passedIdEntry;
            }

            let {requestedEntry} = getSpecificEntryFromGroupsArray({
                groups: fetchedGroups,
                idkey: terms.mode.idkey,
                identry
            });
            setEntryUpdateDirect((prev) => {
                return {
                    ...prev,
                    activeEntry: requestedEntry
                }
            })
        }
    }

    const deleteSelectedEntry = async () => {

        //console.log("DeleteEntry deleteSelectedEntry firing with identry", identry, "and entryUpdate", entryUpdate);
        let res = await LibraryService.deleteEntry({
            identry: entryUpdate.activeEntry[terms.mode.identry],
            idsite: (siteData.isSiteLibrary ? siteData.idsite : null),
            mode, 
        });
        
        if (res.success){
            document.getElementById("close-modal-button").click();
            refreshGroups(true);
        }

        setDeleteEntryTrigger(false);
    }

    const deleteGroup = async () => {
        if (siteData.isMasterLibrary === true || siteData.isSiteLibrary === false) return;
        else {

            let res = await LibraryService.deleteGroup({
                mode, 
                groupName: groupUpdate.activeGroup, 
                idsite: (siteData.isSiteLibrary ? siteData.idsite : null)
            });

            console.log("deleteGroup res:", res);
            
            if (res.success){
                refreshGroups(true);
            }

            setDeleteGroupTrigger(false);
        }
    }

    const resetSiteLibrary = async () => {
        if (!isSiteLibrary) {
            setResetSiteLibraryTrigger(false);
            return;
        } else {
            let {idsite} = siteData;
            let res = await LibraryService.resetSiteLibrary({mode, idsite});

            if (res.success){
                refreshGroups(false)
            }

            setResetSiteLibraryTrigger(false);
        }
    }

    if (unit == null) return null;

    else return(<>
        
            <header className="section-header mt-7 mb-0 bg-white" style={{zIndex: 500, position: 'sticky', top: 0}}>
                <h2 className="d-inline-block"><strong>
                    {isMasterLibrary ? `${unit.name} Master Library` : isSiteLibrary ? `${siteData.siteName} Library` : "" } 
                </strong></h2>
                {isSiteLibrary ? <span onClick={() => setResetSiteLibraryTrigger(true)} className="ml-5 text-small cursor d-inline-block">♻️</span> : null}

                {isSiteLibrary ? 
                    <Event eventtype="etSiteLibraryNotif" />
                : null}

            </header>
            <div className="section pt-1" >
                <div className={"container" + (isSiteLibrary ? " bg-light shadow-sm p-5" : "")}>

                    <div className="col-lg-10 mx-auto mb-6 p-0">  
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <Link className={"nav-link px-7 bg-" + (isSiteLibrary ? "light " : "white ") + (mode == terms.type1.simple ? "active" : null) }
                                    to={"/app/"+(isSiteLibrary ? "site" : "master")+"-library-"+terms.type1.simplePlural}
                                >
                                    {terms.type1.displayPlural}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link px-7 bg-" + (isSiteLibrary ? "light " : "white ") + (mode == terms.type2.simple ? "active" : null) }
                                    to={"/app/"+(isSiteLibrary ? "site" : "master")+"-library-"+terms.type2.simplePlural}
                                >
                                    {terms.type2.displayPlural}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="row">
                        <div className="col-lg-10 mx-auto border-bottom p-0 m-0 my-lg-4 pt-lg-4 pb-lg-7">
                            <div className="col-lg-10 accordion accordion-light accordion-arrow-right  ml-lg-6" id="practices-1">

                                {loading ? 
                                    `Getting ${terms.mode.displayPlural}...`
                                :
                                    groups.map((group, key) => {
                                        return <DisplayGroup
                                            group={group}
                                            key={key}
                                            mode={mode}
                                            setEntryUpdate={setEntryUpdate}
                                            setGroupUpdate={setGroupUpdate}
                                            groupIndex={key}
                                            terms={terms}
                                            siteData={siteData}
                                        />
                                    })
                                }

                                <div className="row  mt-6 ml-7">
                                    <Link className="btn btn-outline-primary bg-white text-capitalize" to={`/app/${mode}-group-${isSiteLibrary ? "site" : "master"}-setup`}>+ Add {terms.mode.display} Group</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <ViewEntryModal
            siteData={siteData}
            id="view-practice"
            entry={entryUpdate.activeEntry}
            terms={terms}
            mode={mode}
            footer = {() => {
                return <>
                    <button type="button" 
                        class="btn btn-xs btn-outline-danger mt-1 ml-4" 
                        onClick={() => {
                            console.log("DeleteEntry onClick firing")
                            setEntryUpdate({
                                typeOfUpdate: "deleteEntry",
                                activeEntry: entryUpdate.activeEntry
                            })
                        }}
                    >
                        Delete {terms.mode.display}
                    </button>
                    {siteData.isSiteLibrary ? <button type="button" 
                        class="btn btn-outline-primary mr-4" 
                        data-toggle="modal" data-target="#copyMoveEntry"
                        onClick={() => {
                            setEntryUpdate({
                                typeOfUpdate: "copyMoveEntry",
                                activeEntry: entryUpdate.activeEntry
                            })
                        }}>
                        Copy/Move {terms.mode.display} Entry
                    </button> : null}
                    <button type="button" 
                        class="btn btn-outline-primary mr-4" 
                        data-toggle="modal" data-target="#editAddEntry"
                        onClick={() => {
                            setEntryUpdate({
                                typeOfUpdate: "editEntry",
                                activeEntry: entryUpdate.activeEntry
                            })
                        }}>
                        Edit {terms.mode.display}
                    </button>
                </>
            }}
         / >

         <CopyMoveEntry
            siteData={siteData}
            oldGroup={entryUpdate.activeEntry ? entryUpdate.activeEntry.type : ""}
            mode={mode}
            terms={terms}
            groups={groups}
            callback={() => refreshGroups(true)}
            identry={entryUpdate.activeEntry ? entryUpdate.activeEntry[terms.mode.idkey] : ""}
         />

        <EditOrAddEntry
            siteData={siteData}
            passedActiveEntry = {entryUpdate.activeEntry}
            passedActiveGroup = {groupUpdate.activeGroup || entryUpdate.activeEntry.type}
            mode = {mode}
            terms={terms}
            trigger={editOrAddEntryTrigger}
            callback = {(identry) => {
                refreshGroups(true, identry);
                setEditOrAddEntryTrigger(false);
            }}
            onClose = {() => {
                if (editOrAddEntryTrigger){
                    setEditOrAddEntryTrigger(false)
                }
            }}
         />
         <EditGroupName
            siteData={siteData}
            oldName={groupUpdate.activeGroup}
            mode={mode}
            terms={terms}
            callback={() => refreshGroups(true)}
         />
         <SuccessPopup
            popupId={"deleteEntryPopup"+terms.mode.simple}
            title="Warning!"
            style="warning"
            message={"Really delete this " + terms.mode.display + "?"}
            buttonLabel="Confirm"
            trigger={!!deleteEntryTrigger}
            callback={deleteSelectedEntry}
            useButton = {true}
            disallowClose = {false}
            closeCallback={() => {
                if (deleteEntryTrigger){
                    console.log("DeleteEntry closeCallback firing")
                    setDeleteEntryTrigger(false)
                }
            }}
        />

        <SuccessPopup
            popupId={"resetSiteLibrary-"+terms.mode.simple}
            title="Really reset?"
            style="warning"
            message={"You will lose all changes specific to the "+siteData.siteName+" library. Continue?"}
            buttonLabel="Yes, reset"
            useCancelButton={true}
            trigger={!!resetSiteLibraryTrigger}
            callback={resetSiteLibrary}
            useButton = {true}
            disallowClose = {false}
            closeCallback={() => {
                //if (resetSiteLibrary){
                    console.log("resetSiteLibrary closeCallback firing")
                    setResetSiteLibraryTrigger(false)
                //}
            }}
        />

        <SuccessPopup
            popupId="deleteGroupPopup"
            title="Warning!"
            style="warning"
            message={"Really delete this " + terms.mode.display + " group?"}
            buttonLabel="Confirm"
            trigger={deleteGroupTrigger}
            callback={() =>  {deleteGroup();}}
            useButton = {true}
            disallowClose = {false}
            closeCallback={() => setDeleteGroupTrigger(false)}
            />
        </>)


}

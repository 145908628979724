import _ from 'lodash';
import identifyIsDocumentationOnlyBMP from './identifyIsDocumentationOnlyBMP'


export default function splitDocumentationAndCorrectionBMPs(passedLoggedBMPs){
	
	let correctionBMPs = [];
	let documentationBMPs = [];

	for (var i = 0; i < passedLoggedBMPs.length; i++){
		let bmp = passedLoggedBMPs[i];

		if (identifyIsDocumentationOnlyBMP(bmp)){
			console.log("splitDocumentationAndCorrectionBMPs bmp is documentation only", bmp);
			documentationBMPs.push(_.cloneDeep(bmp));
		} else {
			console.log("splitDocumentationAndCorrectionBMPs bmp is correction", bmp);
			correctionBMPs.push(_.cloneDeep(bmp))
		}
	}

	console.log("splitDocumentationAndCorrectionBMPs correctionBMPs:", correctionBMPs);
	console.log("splitDocumentationAndCorrectionBMPs documentationBMPs:", documentationBMPs);

	return {correctionBMPs, documentationBMPs}

}
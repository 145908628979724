import _ from 'lodash';

export function etchLibraryTerms(mode = undefined){
	let type1 = {
		simple: "practice",
		simplePlural: "practices",
		simpleArticle: "a ",

		display: "BMP",
		displayPlural: "BMPs",
		displayArticle: "a ",

		identry: 'idpractice',
		idkey: 'idpractice', //Duplicate for the sake of semantic code
	}


	let type2 = {
		simple: "action",
		simplePlural: "actions",
		simpleArticle: "an ",

		display: "Corrective Action",
		displayPlural: "Corrective Actions",
		displayArticle: "a ",

		identry: 'idaction',
		idkey: 'idaction', //Duplicate for the sake of semantic code

	}


	let library = {
		display: "PracticeActions",
		simple: "practiceactions"
	}



	let originalPracticeTerms = _.cloneDeep(type1);
	type1.oppositeTerms = _.cloneDeep(type2);
	type2.oppositeTerms = _.cloneDeep(originalPracticeTerms);

	let toReturn = {type1, type2, library}

	if (mode === "practice"){
		toReturn.opposite = _.cloneDeep(type2);
		toReturn.mode = _.cloneDeep(type1);
	} else if (mode === "action"){
		toReturn.opposite = _.cloneDeep(type1);
		toReturn.mode = _.cloneDeep(type2);
	}

	return toReturn;
}
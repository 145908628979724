import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
//import {usePrevious} from '../../../../../utils'

function Dropdown ({ 
	title,
	value,
	dropdownValues,
	onChange,
	placeholder,
	inputClassName,
	titleClassName,
	wrapperClassName,
	disabled,
	multiple,
	name
}){

	let [multiValue, setMultiValue] = useState(value)

	useEffect(() => {
		if (multiple === true && value !== undefined && value !== null){
			setMultiValue(value.split(','));

			/*const options = document.querySelectorAll(`#${name}-multiselect`);
			let loadedOptions = Array.from(options).map(el => {
				if (values.indexOf(el.value) !== -1 ) el.selected = true;
				return el;
			})*/
			
		}
	}, [value])

	let renderOptions = () => {
		return (Array.isArray(dropdownValues) && !_.isEmpty(dropdownValues) ? dropdownValues.map((value, key) => {
				return <option value={value} key={key}>{value}</option>
			}) : null )
	}

	let onChangeMulti = (e) => {
		const selected = document.querySelectorAll(`#${name}-multiselect option:checked`);
		const values = Array.from(selected).map(el => el.value);
		onChange(values.toString());
	}

	let onChangeSingle = (e) => {
		onChange(e.target.value)
	}


	return <div className={wrapperClassName}>
		<label className={titleClassName}>{title}</label>

		{multiple ? 
			<select id={name+"-multiselect"} name={name} className={inputClassName} value={multiValue} onChange={onChangeMulti} multiple>
				{renderOptions()}
			</select>

		:
			<select name={name} className={inputClassName} value={value} onChange={onChangeSingle}>
				<option value="" disabled selected>{placeholder}</option>
				{renderOptions()}
			</select>
		}

		
	</div>

}

Dropdown.defaultProps = {
	inputClassName: "form-control strong fs-16", 
	titleClassName: "text-primary",
	wrapperClassName: "col-12 float-left form-group contrast",
	title: null,
	multiple: false,
	disabled: false,
}

export default Dropdown;
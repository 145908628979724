import React, {Component, Fragment, useState, useEffect} from 'react'
import UploadImages from '../Modals/UploadImages'
import { url } from '../../../constants'
import panels from '../../../constants/panels'
import {Link} from 'react-router-dom'

import ConnectionModal from "../Modals/ConnectionModal"
import EnterGroup from "./EnterGroup"
import _ from 'lodash'

import {
    LibraryService
} from '../../../services'

import {
    etchLibraryTerms,
    etLoadSiteLibraryData,
    updateObjectBasedOnKey,
    detectIsValidObjectInGroup,
} from '../../../utils'

export default function AddGroup ({unit, mode, history, isSiteLibrary, isMasterLibrary}) {

    const smallCharLimit = 140;
    const bigCharLimit = 65535;
    const terms = etchLibraryTerms(mode);
    const defaultEntryObj = {name: "", description: "", pictures: [], connectionData: []}
    const defaultGroupObj = {name: "", entries: [
        _.cloneDeep(defaultEntryObj),
        _.cloneDeep(defaultEntryObj),
        _.cloneDeep(defaultEntryObj),
    ]}
    const emptyEntryObj = _.cloneDeep(defaultEntryObj);

    const [groups, setGroupsDirect] = useState([_.cloneDeep(defaultGroupObj)]);
    const [oppositeLibrary, setOppositeLibrary] = useState([]);
    const [sendingData, setSendingData] = useState(false);
    const [success, setSuccess] = useState(false);
    const [activeEntryIndex, setActiveEntryIndex] = useState(-1);
    const [activeGroupIndex, setActiveGroupIndex] = useState(-1);
    let [siteData] = useState(etLoadSiteLibraryData({isMasterLibrary, isSiteLibrary}));


    useEffect(() => {
        getOppositeLibrary();
    }, [JSON.stringify(unit)])


    const getActiveEntry = () => {
        let toReturn = _.cloneDeep(defaultEntryObj);
        let isValidIndex = detectIsValidObjectInGroup(groups, activeGroupIndex, activeEntryIndex);
        if (isValidIndex){
            toReturn = _.cloneDeep(groups[activeGroupIndex].entries[activeEntryIndex]);
        }

        return toReturn;
    }

    const getOppositeLibrary = async () => { 
        if (unit != null){
            setOppositeLibrary(
                await LibraryService.getLibrary(unit.idworkgroup, terms.opposite.simple)
            )
        }
    }


    const setEmptyState = async () => {
        setGroupsDirect(_.cloneDeep(defaultGroupObj))
    }

    const updateGroupIndex = async (groupIndex) => {
        let isValidIndex = detectIsValidObjectInGroup(groups, groupIndex);
        if (isValidIndex){
            setActiveGroupIndex(groupIndex);
        }
    }

    const updateEntryIndex = async (groupIndex, entryIndex) => {
        let isValidIndex = detectIsValidObjectInGroup(groups, groupIndex, entryIndex);
        if (isValidIndex){
            setActiveGroupIndex(groupIndex);
            setActiveEntryIndex(entryIndex);
        }
    }

    const updateGroup = (key, value, groupIndex) => {
        setGroupsDirect((prev) => {
            let newGroups = _.cloneDeep(prev);
            newGroups[groupIndex][key] = value;
            return newGroups;
        })
    }

    const updateEntry = (key, value, groupIndex, entryIndex) => {
        console.log("Calling updateEntry with params key", key, "value", value, "groupIndex", groupIndex, "entryIndex", entryIndex)
        setGroupsDirect((prev) => {
            let newGroups = _.cloneDeep(prev);
            let isValidIndex = detectIsValidObjectInGroup(groups, groupIndex, entryIndex);
            console.log("updateEntry isValidIndex", isValidIndex);

            if (isValidIndex){
                 newGroups[groupIndex].entries[entryIndex][key] = value;
            }

            console.log("updateEntry newGroups", newGroups);
           
            return newGroups;
        })
    }


    const addEntry = (groupIndex) => {
        setGroupsDirect((prev) => {
            let newGroups = _.cloneDeep(prev);
            newGroups[groupIndex].entries.push(_.cloneDeep(defaultEntryObj));
            return newGroups;
        })
    }

    const sendData = async () => {
        setSendingData(true);
        let groupsObj = _.cloneDeep(groups);

        for (var i = 0; i < groupsObj.length; i++){
            for (var j = 0; j < groupsObj[i].entries.length; j++){
                if (groupsObj[i].entries[j].name == ""){
                    groupsObj[i].entries[j] = undefined;
                } else {
                    groupsObj[i].entries[j].oppositeRelationships = undefined;
                }
            }
        }

        console.log("sendData groupsObj:", groupsObj);

        let res = await LibraryService.addGroupsToLibrary({
            groups: groupsObj, 
            mode, 
            idsite: (siteData.isSiteLibrary ? siteData.idsite : null)
        });
        console.log(res);
        if (res.success == true){
            history.push('/app/'+(siteData.isMasterLibrary ? 'master' : 'site')+'-library-'+mode+'s')
        } else {
            setSendingData(false);
            setSuccess(false);
        }
    }

    const addPictures = (pictures) => {
        groups[activeGroupIndex].entries[activeEntryIndex].pictures = pictures;
        setGroupsDirect(groups);
    }

    const goBack = () => {
        if (document.referrer.indexOf(url.frontend) == -1){
            history.push("/app/loading")
        } else {
            history.goBack();
        }
    }

    /*const submitIsDisabled = () => {
        if (success || sendingData) return true;
        else {
            if (!_.isEmpty(groups)){
                if (groups[0] !== undefined){
                    if (groups[0].name == ""){
                        return true;
                    } else if (groups[0].entries[0].name == ""){
                        return true;
                    } else if (groups[0].name.length > smallCharLimit){
                        return true;
                    }

                    let toReturn = false;

                    for (var i = 0; i < groups[0].entries.length; i++){
                        let {name, description} = groups[0].entries[i];
                        if (name.length > smallCharLimit) toReturn = true;
                        if (description.length > bigCharLimit) toReturn = true;
                    }

                    return toReturn;
                }
            }
        }
        return false;
    }*/


   // let {workGroup, groups, activeGroupIndex, activeEntryIndex, sendingData} = this.state;
    //let pictures = (activeEntry ? activeEntry.pictures : [])

    return(<Fragment>
        <main className="main-content">
            <header className="section-header mt-5 mb-0">
                <h2><strong>{isSiteLibrary ? siteData.siteName : unit.name + " Master"} {terms.mode.display} Group Setup</strong></h2>
            </header>
            <div className="section pt-2">
                <div className={"container" + (isSiteLibrary ? " bg-light shadow-sm p-5" : "")}>
                    
                    {!_.isEmpty(groups) ? groups.map((group, groupIndex) => 
                        <EnterGroup
                            siteData={siteData}
                            group={group}
                            groupIndex={groupIndex}
                            smallCharLimit={smallCharLimit}
                            bigCharLimit={bigCharLimit}
                            mode={mode}
                            terms={terms}
                            updateEntryIndex={updateEntryIndex}
                            updateEntry={updateEntry}
                            updateGroup={updateGroup}
                            addEntry={addEntry}
                            key={groupIndex}
                        />
                    ) : null}

                    <div className="col-lg-10 mx-auto mb-6 flexbox pt-4 border-top">
                        <Link className="btn btn-secondary" to={"/app/workunit-library-"+mode+"s"}><i className="fa fa-angle-left ml-1"></i> CANCEL</Link>
                        <button className="btn btn-primary" role="button" onClick={sendData} disabled={/*submitIsDisabled()*/ null}>{sendingData ? <img src="../img/app/photoswipe/preloader.gif" /> : null}SAVE ALL</button>
                    </div>

                </div>
            </div>
        </main>

         <UploadImages
            callback={(pictures) => updateEntry("pictures", pictures, activeGroupIndex, activeEntryIndex)}
            capitalizedModeName={terms.mode.display}
            identry={activeEntryIndex}
            pictures={getActiveEntry().pictures}
            terms={terms}
        />

        <ConnectionModal
            oppositeRelationships={oppositeLibrary}
            terms={terms}
            passedConnectionData={getActiveEntry().connectionData}
            identry={activeEntryIndex}
            callback={(connectionData) => {
                updateEntry(
                    "connectionData",
                    connectionData,
                    activeGroupIndex,
                    activeEntryIndex,
                )
            }}
        />

    </Fragment>)

}

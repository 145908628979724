import React, {Component, useState, useEffect, Fragment}  from 'react';
import Collapse from 'react-bootstrap/Collapse';
import {Link, useHistory, useRouteMatch} from 'react-router-dom'
import panels from '../../../constants/panels';

export default function SidebarCategory({categoryName, icon, links, activePanel, displayThis}) {

	let [submenuOpen, setSubmenuOpen] = useState(false);
	let [submenuActive, setSubmenuActive] = useState(false);
	let displayStates = links.map(({displayStateName}) => {return displayStateName})
	const history = useHistory()

	let match = useRouteMatch("/app/:page");	

	useEffect(() => {
		setSubmenuActive(activePanel.menu == categoryName)
	}, [activePanel.menu])

	let onLinkClick = (path, callback) => {
		if (callback) callback()
		history.push(path);
	}

	console.log("Sidebar match:", match);

    return(
    	<li>
            <a href="#" onClick={() => setSubmenuOpen(!submenuOpen)} 
              className={"text-light fs-18 fw-400 pl-4 pb-2 dropdown-toggle "
               + (submenuActive ? (submenuOpen ? "bg-secondary" : "bg-light") : null)}>
              <i className={icon + " pr-2"} aria-hidden="true"></i>{categoryName}
            </a>
            
            <Collapse in={submenuOpen}>
              <ul className="list-unstyled pb-4 border-bottom mb-3">
              	{links.map((link, key) => {
              		let {path, linkName, showInSidebar, sidebarLinkClickCallback} = link;

              		if (showInSidebar && displayThis(link)){
	              		return(<li key={key}>
	              			<Link onClick={() => onLinkClick(path, sidebarLinkClickCallback)}
	              			className={"text-light fs-16 fw-300 pl-7 " + (match.url == path ? 'bg-pale-primary' : null)}>
	              				{linkName}
	          				</Link>
	          			</li>)
          			} else return null;
              	})}
              </ul>
            </Collapse>

        </li>
	)
}
import { url } from '../../../../constants'
import determineBMPStatus from './determineBMPStatus';

export default function getIcon(activeBMP = null){

	let toReturn = ""

	if (activeBMP){
		toReturn = getIconForBMP(activeBMP)
	} else {
		toReturn = getIconsForMap();
	}
	
	return toReturn;

}

function getIconForBMP(activeBMP){
	let status = determineBMPStatus(activeBMP);
	let letterLabel = activeBMP.letterLabel;

	//if (type == "Surplus/Export"){
		return img('my-material-surp')
	/*} else if (type == "Shortage/Import"){
		return '../../img/map-markers/map-marker-my-material-short.svg'
	}*/

}

function getIconsForMap(){
	let alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
	let arr = ['my-material-surp'];
	let toReturn = [];

	for (var i = 0; i < arr.length; i++){
		toReturn.push({
			key: arr[i],
			val: /*convertSVGToImage(img(arr[i]))*/ img(arr[i], false)
		})
	}

	return toReturn;

}

function convertSVGToImage(imgUrl){
	let imageObj = new Image();
	imageObj.classList.add('marker');
	imageObj.src = imgUrl;
	return imageObj;	
}

function img(uniqueURL, useFixedsSizeMarkers = false){
	return '../../img/map-markers'+(useFixedsSizeMarkers ? '-25' : '')+'/map-marker-'+uniqueURL+'.svg';
}
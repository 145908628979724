import React from 'react';

export default function DisplayEntry({entry, groupIndex, mode, terms, setEntryUpdate, siteData}){

	return <li>
        <a href="#" className="text-primary strong"
        	data-toggle="modal" 
        	data-target="#view-practice" 
        	onClick={() => setEntryUpdate({activeEntry: entry, typeOfUpdate: "view"})}>
        		{entry.name}
		</a>
    </li>

}
import React from 'react'

export default function EtSiteLibraryNotif({seen, markAsSeen}){

	if (seen == true) return null;
	else {

		return <div class="alert alert-info alert-dismissible fade show" role="alert">
 			<strong>Heads up!</strong> You are now editing a site-specific library.
			<button type="button" class="close" data-dismiss="alert" onClick={markAsSeen} aria-label="Close">
			    <span aria-hidden="true">&times;</span>
			</button>
		</div>
	}

}
import React, {Fragment} from 'react'
import { url } from '../../../../../constants'
import moment from 'moment';

export default function DisplayDocumentation({label, obj, includeInitials}){


	return(<>
		
        <div class="col-12 clearfix mt-5 pl-0 pr-5">
            <p class="fs-17 float-left mx-4"><strong>{label}:</strong></p>
        </div>

        <div class="col-12 clearfix px-0">

            {obj.photos.length == 0 ? null :
                obj.photos.map((image, key) => {
                    return <div class="col-6 float-left" key={key}>
                        <img src={url.backend + "/images/practiceactions/" + image} alt="..." class="img-thumbnail"/>
                    </div>
                })
            }

        </div>  

        {includeInitials ? 

            <div class="col-12 my-4 px-4">
                <div class="form-group flexbox gap-x">
                    <label>Completed on {moment(obj.date).format("MM/DD/YYYY")}</label>
                    <label>Completed by {obj.initials}</label>
                </div>

                <div class="form-group">
                    <p>{obj.description}</p>
                </div>

            </div>

        :

            <div class="col-12 my-4 px-4">
                <div class="form-group">
                    <label>Documented on {moment(obj.date).format("MM/DD/YYYY")}</label>
                </div>

                <div class="form-group">
                    <p>{obj.description}</p>
                </div>

            </div>

        }

    </>)
}
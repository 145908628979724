import React, {Fragment} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import PhoneInput from 'react-phone-input-2'
import '../../../../People/WorkUnitCrew/phonestyles.css'
import textLimits from '../../constants/textLimits'
import {alphanumericSort} from "../../../../../utils"

/*First Page*/

export default function ProjectInfo(props){

	let {reportState, handleChange, cancel, saveReportForLater, setSelectedSite} = props;
	let {sitesList, flexdata} = reportState;


	return(<>

		<InspectionWrapper
	      title="Erosion Control Inspection"
	      navButtons={[
	        {
	          className: "btn-secondary", 
	          onClick: cancel, 
	          label: "CANCEL"
	        },
	        {
	          className: "btn-outline-primary",
	          onClick: saveReportForLater,
	          label: "Save for Later"
	        },
	        {
	          className: "btn btn-primary",
	          onClick: () => handleChange("phase", "InspectionInfo"),
	          label: <>CONTINUE <i class="fa fa-angle-right ml-1"></i></>
	        }
	      ]}
	    >
		

			<div class="form-group contrast">
			<label class="text-primary">Construction Site Name</label>
			{/*<div class="col-12 float-left px-0 mb-4">
				<div class="col-12 contrast float-left px-0">*/}
					<select class="form-control strong fs-16" 
						onChange={(e) => setSelectedSite(e.target.value)} 
						value={reportState.idsite}
					>
						<option value={null}>Project Site</option>
						{alphanumericSort(sitesList, "name").map(({idsite, name}, index) => {return(
							<option value={idsite}>{name}</option>
						)})}
					</select>
				{/*</div>
			</div>*/}
			</div>

			<div class="form-group contrast">
				<label class="text-primary">Site ID #</label>
				<input class="form-control strong fs-16" 
					type="text" 
					name="officialsiteid" 
					value={flexdata.officialsiteid} 
					maxLength={textLimits.textInputCharLimitStandard}
					onChange={(e) => handleChange("officialsiteid", e.target.value)} 
					placeholder="Site ID #"
				/>
			</div>

			<div class="form-group contrast">
				<label class="text-primary">Location, Municipality, County</label>
				<input class="form-control strong fs-16" 
					type="text" 
					name="municipality" 
					value={flexdata.municipality}
					maxLength={textLimits.textInputCharLimitExtra}
					onChange={(e) => handleChange("municipality", e.target.value)} 
					placeholder="Location, Municipality, County" 
				/>
			</div>

			<div class="form-group contrast">
				<label class="text-primary">Contractor/Onsite Contact</label>
				<input class="form-control strong fs-16" 
					type="text" 
					name="onsitecontact"
					value={flexdata.onsitecontact}
					maxLength={textLimits.textInputCharLimitStandard}
					onChange={(e) => handleChange("onsitecontact", e.target.value)}
					placeholder="Contractor/Onsite Contact"
				 />
			</div>

			<div class="form-group contrast">
				<label class="text-primary">Onsite Phone/Cell</label>
				<PhoneInput 
					autoFormat={true}
					country='us'
					onlyCountries={['us']}
					id="phonenumber"
					label="Onsite Phone/Cell"
					name="phonenumber"
					inputClass="form-control strong fs-16"
					dropdownClass="fs-16"
					placeholder="Onsite Phone/Cell"
					value={flexdata.onsitephone} 
					onChange={phonenumber => handleChange('onsitephone', phonenumber)}
				/>

			</div>


		</InspectionWrapper>
          

	</>)

}

import React, {Fragment} from 'react'
import InspectionMapCore from '../HereMapComponents/InspectionMapCore'
import _ from 'lodash';

export default function BMPLocationEntry({reportState, activeBMP, setActiveBMP}){

	return(<>

		<section class="section" style={{minHeight: '400px'}}>

			<InspectionMapCore
				defaultCenter={reportState.mapCenter}
				defaultZoom={reportState.mapZoom}
				displayMode={false}
				callback={(location) => {
					let cloneBMP = _.cloneDeep(activeBMP);
					let {lat, lng} = location;
					cloneBMP.location = {lat, lng};
					setActiveBMP(cloneBMP);
				}}
			/>

        </section>

	</>)

}

import React from "react"
import {useHistory} from 'react-router-dom'
import {
    etchLibraryTerms,
    etGoToLibrary
} from '../../../utils'

const terms = etchLibraryTerms();

export default function SiteListItem({site}){

	const history = useHistory()

	return <li className="ml-md-7 pt-2 pt-md-1 pb-3 d-flex flex-column flex-md-row border-bottom justify-content-md-between">
		<span className="text-center text-md-left mb-2 mb-md-0">{site.name}</span>
		<span className="d-flex justify-content-between d-md-inline">
			{/*<button className="btn btn-sm btn-outline-primary ml-1">
				View
			</button>
			<button className="btn btn-sm btn-outline-success ml-1">
				Edit
			</button>
			<button className="btn btn-sm btn-outline-info ml-1">
				Archive
			</button>
			<button className="btn btn-sm btn-outline-warning ml-1">
				Delete
			</button>*/}

			<button onClick={() => history.push(etGoToLibrary(site))} className="btn btn-sm btn-outline-secondary ml-1">
				Edit Library
			</button>
		</span>
	</li>

}
import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
//import {usePrevious} from "../../../../utils"

export default function RequiredCorrectiveActions({label, initCas, callback}){

	let [cas, setCasDirect] = useState(_.cloneDeep(initCas));
    let [hasCAs, setHasCAs] = useState(true);

	let setCas = (index) => {
		let cloneCAs = _.cloneDeep(cas)
        cloneCAs[index].status = !cloneCAs[index].status;
		setCasDirect(cloneCAs);
		callback(cloneCAs);
	}

    let setIsDocumentationOnly = (newVal) => {
        setHasCAs(newVal)

        if (newVal == false){
            callback(null)
        } else {
            callback(_.cloneDeep(cas));
        }
       
    }


	return(<>
		
		<div class="col-12 mt-3 px-4">
            <p class="mb-1 fs-17 mb-4 border-bottom">
                <div class="custom-control custom-checkbox" checked={hasCAs} onClick={(e) => {
                    e.preventDefault();
                    setIsDocumentationOnly(!hasCAs)
                }}>
                    <input type="checkbox" checked={hasCAs} class="custom-control-input" id={"documentationOnly"}/>
                    <label class="custom-control-label" htmlFor={"documentationOnly"} style={{fontSize: 'inherit'}}>
                        {hasCAs ? <>
                            <strong>Required CAs</strong>
                            <p className="mb-0 ml-2">(Uncheck if no required CAs)</p></> 
                        : 
                            <strong>No CAs Required</strong>
                        }
                    </label>
                </div>

            </p>

            {hasCAs ? 
                <div class="form-group ml-6 pb-4 border-bottom">
                    <div class="custom-controls-stacked pb-2">

                        {cas && cas.length > 0 ? cas.map((ca, index) => {
                            return <div class="custom-control custom-checkbox" key={ca.idaction} onClick={(e) => {
                                e.preventDefault();
                                setCas(index);
                            }}>
                                <input type="checkbox" value={ca.status} class="custom-control-input" id={"ca"+ca.idaction}/>
                                <label class="custom-control-label fs-17" htmlFor={"ca"+ca.idaction}>{ca.name}</label>
                            </div>
                        })
                        :
                            <span>Error: No corrective actions found for this BMP</span>
                        }                          

                    </div>

                </div>
            : null}                              

        </div>

	</>)

}
import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import Document from '../../EntryComponents/generic/Document';
import NoActiveBMPError from '../../DisplayComponents/generic/NoActiveBMPError'

export default function BMPEnterConditions(props) {
	let {handleChange, handleBMPChange, cancelBMPFlow, completeReport, reportState, saveReportForLater} = props;
	let {sendingReport, loggedBMPs, activeBMPIndex} = reportState;

    let createActiveBMP = () => {
        console.log("BMPForm loggedBMPs:", loggedBMPs);
        console.log("BMPForm activeBMPIndex:", activeBMPIndex);
        return _.cloneDeep(loggedBMPs[activeBMPIndex])
    }

    let setActiveBMP = (bmp) => {
        console.log("Calling setActiveBMP");
        handleBMPChange(bmp)
        setActiveBMPDirect(bmp);
    }

    let moveOn = () => {

        let {conditionDocumentation} = activeBMP;

        let currentValidConditions = true;

        if (conditionDocumentation.description == "" && conditionDocumentation.photos.length == 0){
            currentValidConditions = false;
        } else {
            currentValidConditions = true;
        }

        if (currentValidConditions){
            handleChange("phase", "BMPEnterInitialCorrections");
        } else if (currentValidConditions != validConditions) {
            setValidConditions(currentValidConditions)
        }

    }
   
    let [activeBMP, setActiveBMPDirect] = useState(createActiveBMP());
    let [validConditions, setValidConditions] = useState(true);

	if (_.isEmpty(activeBMP)) return <NoActiveBMPError />;

    else return(<div>

        <InspectionWrapper
          title="Log BMP Event"
          navButtons={[
            {
              className: "btn-secondary", 
              onClick: cancelBMPFlow,
              label: <><i className="fa fa-angle-left mr-1"></i> CANCEL</>
            },
            {
              className: "btn-outline-primary",
              onClick: moveOn,
              label: <>ENTER CORRECTION INFO <i class="fa fa-angle-right ml-1"></i></>
            },

          ]}
          isBMPFlow={true}
        >

                <div class="col-12 mb-4 p-3 ml-2 border-bottom">
                    <p class="mb-1 fs-20"><i class="fa fa-cube px-2" aria-hidden="true"></i><strong>{activeBMP.type}: {activeBMP.name}</strong></p>
                </div>        

                <Document
                    label="BMP Conditions"
                    uniqueID="BMPFormBMPCondition"
                    includeInitials={false}
                    initVal={activeBMP.conditionDocumentation}
                    valid = {validConditions}
                    onChange={(passedObj) => {
                        console.log("Document bmpForm OnChange")
                        let cloneBMP = _.cloneDeep(activeBMP);
                        cloneBMP.conditionDocumentation = _.cloneDeep(passedObj);
                        setActiveBMP(cloneBMP);
                    }}
                />

        </InspectionWrapper>
    </div>)

}
    


import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'

import BMPLocationEntry from '../../EntryComponents/BMPLocationEntry';
import RequiredCorrectiveActions from '../../EntryComponents/RequiredCorrectiveActions';
import NoActiveBMPError from '../../DisplayComponents/generic/NoActiveBMPError'
import Document from '../../EntryComponents/generic/Document';

export default function BMPPlotLocation(props) {
	let {handleChange, handleBMPChange, cancelBMPFlow, completeReport, reportState, saveReportForLater} = props;
	let {sendingReport, loggedBMPs, activeBMPIndex} = reportState;

    let createActiveBMP = () => {
        console.log("BMPForm loggedBMPs:", loggedBMPs);
        console.log("BMPForm activeBMPIndex:", activeBMPIndex);
        return _.cloneDeep(loggedBMPs[activeBMPIndex])
    }

    let setActiveBMP = async (bmp) => {
        console.log("Calling setActiveBMP from BMPPlotLocation with BMP", bmp);
        await handleBMPChange(bmp, true)
        setActiveBMPDirect(bmp);
    }


    let moveOn = () => {
        handleChange("phase", "BMPEnterConditions");
    }
   
    let [activeBMP, setActiveBMPDirect] = useState(createActiveBMP());

	if (_.isEmpty(activeBMP)) return <NoActiveBMPError />;

    else return(<div>

        <InspectionWrapper
          title="Log BMP Event"
          navButtons={[
            {
              className: "btn-secondary", 
              onClick: cancelBMPFlow,
              label: <><i className="fa fa-angle-left mr-1"></i> CANCEL</>
            },
            {
              className: "btn-outline-primary",
              onClick: moveOn,
              label: <>ENTER CONDITIONS <i class="fa fa-angle-right ml-1"></i></>
            },

          ]}
          isBMPFlow={true}
        >

                <div class="col-12 mb-4 p-3 ml-2 border-bottom">
                    <p class="mb-1 fs-20"><i class="fa fa-cube px-2" aria-hidden="true"></i><strong>{activeBMP.type}: {activeBMP.name}</strong></p>
                </div>        


                <div class="col-12">
                    <div class="fs-17 alert alert-info"><strong>Click on the map to plot the location of the BMP:</strong></div>
                    <BMPLocationEntry activeBMP={activeBMP} setActiveBMP={setActiveBMP} reportState={reportState} />
                </div>

        </InspectionWrapper>
    </div>)

}
    


import React, {useState, useEffect, Fragment} from 'react'
import SkillsManager from './SkillsManager'
import {
    WorkGroupsService
} from '../../../services'
import {
    etDisplayEmployeeRole
} from '../../../utils'
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal'
import UpdateCrewMemberDetails from './UpdateCrewMemberDetails'

export default function EditCrewMember({passedEmployee, unit, closeModal}){

	let [employee, updateEmployee] = useState({});
    let [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    let defaultSkillsObj = {skill: {name: ""}, level: "", startDate: null, expiryDate: null}

	let updateEmployeeVals = (key, val) => {
        let clone_employee = _.cloneDeep(employee);
        clone_employee[key] = val;
        updateEmployee(clone_employee);
    }

    let submitEmployee = async () => {
        console.log("EditCrewMember submitEmployee with employee:", employee);
        let res = await WorkGroupsService.editEmployee({employee});
        console.log("EditCrewMember res:", res);
        if (res && res.success) closeModal();
    }

    function formatPhoneNumber(entry = '') {
        if (entry.length < 10) return entry;
        
        const match = entry
            .replace(/\D+/g, '').replace(/^1/, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
        const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
        const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
        const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''    
        return `${part1}${part2}${part3}`
    }

    useEffect(() => {
        let clone_passedEmployee = _.cloneDeep(passedEmployee)
        if (JSON.stringify(employee) !== JSON.stringify(clone_passedEmployee) && clone_passedEmployee != undefined){
            updateEmployee(clone_passedEmployee)
        }
    }, [passedEmployee])

    useEffect(() => {

        let calcedSaveButtonDisabled = true;
        //let clone_employee = _.cloneDeep(employee)

        if (employee.editEmployee == true){
            if (employee.name !== "" && employee.workrole !== "" && employee.role !== "default" && employee.phonenumber !== ""){
                calcedSaveButtonDisabled = false;
            } else {
                calcedSaveButtonDisabled = true;
            }
        } else if (JSON.stringify(employee.newSkills) !== JSON.stringify(defaultSkillsObj)){
            calcedSaveButtonDisabled = false;
        }

        if (calcedSaveButtonDisabled != saveButtonDisabled){
            setSaveButtonDisabled(calcedSaveButtonDisabled);
        }

    }, [employee.editEmployee, employee.name, employee.phonenumber, employee.workrole, employee.role, employee.newSkills])

    return <>

        <Modal.Header>
            <Modal.Title className="strong fs-16">Edit User</Modal.Title>
            <button type="button" class="close" onClick={closeModal} aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </Modal.Header>

        <Modal.Body className="p-0">
              
            <div className="card-body">

                <div className="form-group pb-5">
                    <div className="col-12 p-0 border-bottom mb-4">
                        <label className="strong">Enter their contact info and roles:</label>
                    </div>
                   

                    {!employee.editEmployee ? 

                        <div class="px-4 pt-2 clearfix" style={{width: '100%'}}>
                            <div class="col-12 col-md-2 p-0 lh-1 mt-2 float-left">
                                {employee.name ? <strong class="text-dark">{employee.name}</strong> : <span class="text-light">No name entered</span>}
                            </div>
                            <div class="col-12 col-md-3 p-0 mr-2 float-md-left text-md-right">
                                <span class="small fw-100">{employee.idemployee}</span>
                            </div>
                            <div class="col-12 col-md-2 p-0 float-left ml-md-5">
                                {!!employee.phonenumber ?
                                    <a href={"tel:"+employee.phonenumber}><strong class="small-2 fw-100">{formatPhoneNumber(employee.phonenumber)}</strong></a>
                                :
                                    <strong class="small-2 text-light">No phone entered</strong>
                                }
                            </div> 
                            <div class="col-6 col-md-2 p-0 float-left">
                                <strong class="small-2 fw-100">{employee.workrole || "No role entered"}</strong>
                            </div> 
                            <div class="col-6 col-md-2 p-0 float-right float-md-left text-right text-md-left">
                                <strong class="small-2 fw-100">{etDisplayEmployeeRole(employee.role)}</strong>
                            </div> 
                            <div class="p-0 mx-2 mt-0 float-right">
                                <a class="small item-remove text-dark mt-1 float-right" href="#" onClick={() => updateEmployeeVals("editEmployee", true)}><i class="ti ti-pencil"></i></a>
                            </div>
                        </div>

                    :
                        <div className="row mx-1 ml-md-2 gap-x">
                            <UpdateCrewMemberDetails
                                crewMember={employee}
                                callback={updateEmployeeVals}
                                existingCrewMember={true}
                            />
                        </div>
                    }

                </div>
                
                <SkillsManager
                    passedEmployee={employee}
                    updatePassedEmployee={updateEmployee}
                    useExistingEmployee={true}
                />

            </div>

        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-sm btn-secondary float-right mx-2" onClick={closeModal}>Close</button>
          <button className="btn btn-sm btn-primary float-right mx-2" disabled={saveButtonDisabled} onClick={submitEmployee}>SAVE</button>
        </Modal.Footer>
    </>
}
import moment from 'moment';

export default function determineBMPStatus(bmp = null, reportState = null){

	console.log("Calling determineBMPStatus with bmp:", bmp, "and reportState:", reportState);

	if (bmp == null){
		console.log("determineBMPStatus returning null because passed bmp was null");
		return null;
	} else if (bmp.cas === null){
		return "Documentation";
	} else if (bmp.correctionDocumentation !== null && bmp.correctionDocumentation !== undefined && typeof bmp.correctionDocumentation === "object"){
		let {description, photos} = bmp.correctionDocumentation;
		let hasDescription, hasPhotos = false;

		if (description !== ""  && description !== null && description !== undefined){
			hasDescription = true;
		}

		if (photos !== undefined && photos !== null && photos.length > 0){
			hasPhotos = true;
		}

		console.log("determineBMPStatus hasPhotos:", hasPhotos, "hasDescription:", hasDescription);

		if (hasPhotos || hasDescription){
			console.log("determineBMPStatus returning Complete")
			return "Complete"
		} else {

			if (reportState.date){
				let date = reportState.date.toString();
				let loggedStartDate = moment(date).startOf("date");
				let today = moment().startOf("date");

				console.log("determineBMPStatus loggedStartDate:", loggedStartDate, "today:", today);

				if (loggedStartDate.isBefore(today)){
					console.log("determineBMPStatus returning Overdue")
					return "Overdue"
				} else {
					console.log("determineBMPStatus returning Incomplete")
					return "Incomplete"
				}
			}
		}

	} else {

		console.log("determineBMPStatus returning because bmp was not null but had no correctionDocumentation")
		return null;
	}

}
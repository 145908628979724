import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import determineBMPStatus from '../../utils/determineBMPStatus'
import identifyIsDocumentationOnlyBMP from '../../utils/identifyIsDocumentationOnlyBMP'
/*import BMPEntry from '../../EntryComponents/BMPEntry'
import EmployeeEntry from '../../EntryComponents/EmployeeEntry'*/

import Document from '../../EntryComponents/generic/Document';
import DisplayDocumentation from '../../DisplayComponents/generic/DisplayDocumentation';
import RequiredCorrectiveActions from '../../EntryComponents/RequiredCorrectiveActions';
import BMPLocationDisplay from '../../DisplayComponents/BMPLocationDisplay';
import SelectedCAsDisplay from '../../DisplayComponents/SelectedCAsDisplay';

export default function BMPEventSummaryIncomplete(props) {

	let {handleChange, handleBMPChange, cancel, completeReport, reportState, saveReportForLater} = props;
	let {sendingReport, loggedBMPs, activeBMPIndex, status} = reportState;

	let [activeBMP, setActiveBMPDirect] = useState(_.cloneDeep(loggedBMPs[activeBMPIndex]))
	let [validCorrections, setValidCorrections] = useState(true);
	let [showCADocumentationEntry, setShowCADocumentationEntry] = useState(false);

	useEffect(() => {
		//let status = activeBMPStatus();
		
		//if (status == "Complete"){
			//handleChange("phase", "BMPEventSummaryIncomplete");
		//} else {
			setShowCADocumentationEntry(reportState.currentBMPCorrected);
		//}
	}, [])

	let activeBMPStatus = () => {
		return determineBMPStatus(activeBMP, reportState);
	}

	 let setActiveBMP = (bmp) => {
        console.log("Calling setActiveBMP from BMPEventSummaryIncomplete");
        if (handleBMPChange) handleBMPChange(bmp)
        setActiveBMPDirect(bmp);
    }

    let moveOn = () => {
    	let {correctionDocumentation} = activeBMP;
    	let localValidCorrections = false;

        if (correctionDocumentation.description == "" && _.isEmpty(correctionDocumentation.photos) && !identifyIsDocumentationOnlyBMP(activeBMP)){
            setValidCorrections(false);
        } else {
            setValidCorrections(true);
            localValidCorrections = true;
        }


    	if (status == "finished"){
    		handleChange("phase", "InspectionSummary");
    	} else if (!showCADocumentationEntry){
    		handleChange("phase", "LoggedEventsList");
    	} else {
    		

            if (localValidCorrections){
            	handleChange("currentBMPCorrected", true);
            //	handleChange("phase", "BMPEventSummaryComplete");
            	handleChange("phase", "LoggedEventsList");
            } else {
            	handleChange("phase", "LoggedEventsList");
            }

    	}
    }

    let cancelCA = () => {
    	let cloneBMP = _.cloneDeep(activeBMP);
        cloneBMP.correctionDocumentation = {photos: [], date: new Date(), initials: "", description: ""};
        setActiveBMP(cloneBMP);
    	handleChange('phase', 'prev');
    }


	return(<>

		<InspectionWrapper
          title="Logged BMP"
          navButtons={[
            {
              className: "btn-secondary", 
              onClick: cancelCA,
              label: <><i className="fa fa-angle-left mr-1"></i> CANCEL</>,
              hideConditions: (status == "finished")
            },
            {
              className: "btn-primary" + (status == "finished" ? " mr-auto" : ""),
              onClick: moveOn,
              label: <>DONE <i class="fa fa-angle-right ml-1"></i></>
            },

          ]}
          isBMPFlow={true}
        >

				<div class="col-12 mb-4 p-3 ml-2 border-bottom">
					<p class="mb-1 fs-20"><i class="fa fa-cube px-2" aria-hidden="true"></i><strong>{activeBMP.type}: {activeBMP.name}</strong></p>
				</div>        

				<div class="col-12">
					<BMPLocationDisplay bmpList={[activeBMP]} reportState={reportState} />
				</div>

				<DisplayDocumentation
					includeInitials={false}
                    obj={activeBMP.conditionDocumentation}
                    label={"BMP Conditions"}
				/>

				{!identifyIsDocumentationOnlyBMP(activeBMP) ? <>
					<div class="col-12 mt-3 px-4">
					    <p class="mb-1 fs-17 mb-4 border-bottom"><strong>Required Corrective Actions:</strong></p>

					    <div class="form-group ml-6 pb-4 border-bottom">
					        <div class="custom-controls-stacked pb-2">

					        	<SelectedCAsDisplay
					        		passedCas={activeBMP.cas}
					        		useListView={true}
					        	/> 

					        </div>

					    </div>                              

					</div>

					{showCADocumentationEntry && status != "finished" ? 

						<div class="border-primary col-12">{/*} This div should not appear if user did not click "Yes" on BMP Form and is viewing this page during inspection. Do show this div if page is viewed outside of inspection flow. */}
							
							<Document 

								label="Corrective Action"
	                            uniqueID="BMPFormCorrectiveAction"
	                            initVal={activeBMP.correctionDocumentation}
	                            valid = {validCorrections}
	                            includeInitials={true}
	                            onChange={(passedObj) => {
	                                let cloneBMP = _.cloneDeep(activeBMP);
	                                cloneBMP.correctionDocumentation = _.cloneDeep(passedObj);
	                                setActiveBMP(cloneBMP);
	                            }}

							/>

						</div>

					: null }
				</> : null}

		</InspectionWrapper>
  
	</>)

}
import React, {Fragment, useState, useEffect} from 'react';


function displayStatusLogic({status, reportStatus, onClick}, displayMode){
	console.log("Calling statusDisplay displayStatusLogic with onClick", onClick)

	let renderStatus = () => {
		if (status == "Incomplete"){
			return renderSpecificStatus({
				icon: "fa fa-exclamation-triangle", 
				style: "warning", 
				statusLabel: "Incomplete",
				detailLabel: (reportStatus == "finished" ? "View Record" : "Correct BMP")
			})
		} else if (status == "Overdue") {
			return renderSpecificStatus({
				icon: "fa fa-clock-o", 
				style: "danger", 
				statusLabel: "Overdue",
				detailLabel: (reportStatus == "finished" ? "View Record" : "Correct BMP")
			})
		} else if (status == "Documentation") {
			return renderSpecificStatus({
				icon: "fa fa-check-square", 
				style: "primary", 
				statusLabel: "Documentation Only",
				detailLabel: "View Record"
			})
		} else if (status == "Documentation") {
			return renderSpecificStatus({
				icon: "fa fa-check-square", 
				style: "primary", 
				statusLabel: "Documentation Only",
				detailLabel: "View Record"
			})
		} else {
			return renderSpecificStatus({
				icon: "fa fa-check-square", 
				style: "primary", 
				statusLabel: "Documentation Only",
				detailLabel: "View Record"
			})
		}
	}

	let renderSpecificStatus = (params) => {
		if (displayMode == "JSX" || displayMode === undefined){
			return renderSpecificStatusJSX(params)
		} else {
			return renderSpecificStatusJSON(params)
		}

	}

	let renderSpecificStatusJSX = ({icon, style, statusLabel, detailLabel}) => {

		return <>
			<p className={`strong text-${style} fs-16 mt-3`}>
				<i className={`${icon} mr-2 text-${style}`}></i>
				{statusLabel}
			</p>
			<a 
				href="#" 
				onClick={onClick} 
				className={`btn btn-xs btn-round btn-${style}`}>
				{detailLabel}
			</a>
		</>
	}

	let renderSpecificStatusJSON = ({icon, style, statusLabel, detailLabel}) => {

		console.log("Calling renderSpecificStatusJSON with onClick", onClick)

		let children = [
			{
				elementType: "p",
				attributes: {
					class: `strong text-${style} fs-16 mt-3`,
				},
				children: [
					{
						elementType: "i",
						attributes: {
							class: `${icon} mr-2 text-${style}`
						}
					}, {
						elementType: "span",
						children: statusLabel
					}
				]	
			},
			{
				elementType: "button",
				attributes: {
					class: `btn btn-xs btn-round btn-${style}`
				},
				children: "View in List",
				callback: () => {
					document.getElementById("displayed-bmp-"+onClick).
						scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
				}
			},
		];


		return children;

	}

	return renderStatus();
}

//React Component version
export function StatusDisplay(props) {
	return displayStatusLogic(props, "JSX");
}

//HTML JSON version
export function statusDisplay(params) {
	return displayStatusLogic(params, "JSON");
}


import React from 'react';
import _ from 'lodash';
import {
  isMobileOnly
} from 'react-device-detect'

export default function InspectionFooter({buttons}){

	let renderButtons = () => {
		return (!_.isEmpty(buttons) ? buttons.map(({label, className, onClick, hideConditions}) => {
			if (hideConditions !== undefined && hideConditions == true) return null;
			else return <button 
				type="button" 
				onClick={onClick}
				className={"btn " + className + (isMobileOnly ? " my-4 btn-block" : "")}>
					{label}
			</button>

		}) : null)
	}


	if (isMobileOnly){
		return <div class="mb-6 pt-4 border-top">
			{renderButtons()}
		</div>

	} else {

		return <div class="col-md-10 col-xl-6 mx-auto mb-6 flexbox pt-4 border-top">
			{renderButtons()}
		</div>
    }
                  
}
import React, {Fragment} from 'react'
import _ from 'lodash';


export default function NoActiveBMPError({props}) {

	return <div class="col-12 mb-4 p-3 ml-2 border-bottom">
	        <p class="mb-1 fs-20"><i class="fa fa-cube px-2" aria-hidden="true"></i><strong>BMP Not Found</strong></p>
	        <p>This can happen in rare circumstances where the system erases the BMP early. Please click the "Home" button below to proceed.</p>
	    </div>        


}
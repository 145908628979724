import React, {useState, Fragment} from 'react';
import DisplayEntry from "./DisplayEntry"


export default function DisplayGroup({group, mode, terms, groupIndex, setEntryUpdate, setGroupUpdate, siteData}){


    let [filter, updateFilter] = useState("");


    return(<div className="card" key={groupIndex}>
        <p className="card-title border-bottom strong fs-17">
            <a data-toggle="collapse" href={"#collapse-a-"+groupIndex}>{group.name} <span className="badge badge-pill badge-secondary ml-4">{group.entries.length}</span></a>
        </p>

        <div id={"collapse-a-"+groupIndex} className="collapse" data-parent="#practices-1">
            <div className="card-body">

                <div className="clearfix">
                    <div className="col-lg-8 form-group input-group ml-lg-6 px-0 float-left">
                        <input type="text" className="form-control" placeholder="Search for..."
                            value={filter}
                            onChange={(e) => updateFilter(e.target.value)} />
                        <div className="input-group-append ml-4">
                            <button className="btn btn-xs btn-secondary px-5" type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <button className="btn btn-xs btn-outline-primary float-right mt-2 mr-5" 
                        data-toggle="modal" data-target="#editAddEntry"
                        onClick={() => {
                            console.log("Add button clicked")
                            setEntryUpdate({activeEntry: {identry: 0, name: "", description: "", pictures: []}, typeOfUpdate: undefined})
                            setGroupUpdate({activeGroup: group.name, typeOfUpdate: "addEntry"})
                        }}
                        >
                            + Add {terms.mode.displayArticle} {terms.mode.display}
                    </button>
                </div>

                <ul className="ml-6">
                    {group.entries.map((entry, index) => {
                        if (!filter || (filter && entry.name.toLowerCase().indexOf(filter.toLowerCase()) != -1)){
                            return <DisplayEntry 
                                entry={entry}
                                groupIndex={groupIndex}
                                mode={mode}
                                terms={terms}
                                setEntryUpdate={setEntryUpdate}
                                siteData={siteData}
                            />
                        } else {
                            return null;
                        }
                    })}
                </ul>

                <div className="d-flex">
                    {siteData.isSiteLibrary ? <button 
                        type="button" 
                        className="btn btn-xs btn-outline-danger ml-auto mr-2" 
                        onClick={() => setGroupUpdate({activeGroup: group.name, typeOfUpdate: "delete"})}
                    >
                        Delete {terms.mode.display} Group
                    </button> : null}
                    <button 
                        type="button" 
                        className={"btn btn-outline-primary mr-5" + (siteData.isSiteLibrary ? "" : " ml-auto")}
                        data-toggle="modal" 
                        data-target="#edit-group-name" 
                        onClick={() => setGroupUpdate({activeGroup: group.name, typeOfUpdate: "editGroupName"})}
                    >
                        Edit Group Name
                    </button>
                </div>
            </div>
        </div>
    </div>)
}
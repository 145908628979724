import request from 'superagent';
import Compress from 'compress.js';
import {url} from '../constants';

export function handleFileUpload (files, path, successCallback, errorCallback) {
	
	let compress = new Compress();
    let filesArray = [];
    if (typeof files == "string"){
    	console.log("handleFileUpload files:", files);
    	filesArray = [Compress.convertBase64ToFile(files.split('base64,')[1])]
    } else {

	    for (var i = 0; i < files.length; i++){
	    	filesArray.push(files.item(i));
	    }
	}

    compress.compress(filesArray, {
        size: 4, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 720, // the max width of the output image, defaults to 1920px
        maxHeight: 720, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
    }).then((data) => {
        //alert("handleTempFileUpload called");
        console.log("img upload data:", data);

        let files = [];
        let formData = new FormData();

        for (var i = 0; i < data.length; i++){
            let img1 = data[i]
            let base64str = img1.data
            let imgExt = img1.ext
            let file = Compress.convertBase64ToFile(base64str, imgExt)
            formData.append('picture', file);
        }

        console.log(files);
    
		request
		.post(`${url.backend}/${path}/uploadPhotos`)
		.send(formData)
		.set('Accept', 'application/json')
		.then(response => {
			if (successCallback) successCallback(response.body.fileNames)
		})
		.catch((error) => {
		   if (errorCallback) errorCallback(error);             
		});
	})
}
import React, {Fragment} from 'react'

export default function EmployeeEntry({reportState, handleChange, getNumSelected}){

	let {employeesList, createdby, workUnit, employeeFilter, idsite} = reportState;

	let setSelectedEmployee = (index, val) => {
    	let {employeesList, createdby} = reportState;
        if (employeesList[index].email !== createdby){
    	   employeesList[index].selected = !val;
        }

    	handleChange("employeesList", employeesList);
    }
	
	let renderSelectCrew = () => {

		return(<div className="modal fade" id="select-crew" tabindex="-1" role="dialog" aria-labelledby="select-crew" aria-hidden="true">
			<div className="modal-dialog modal-long" role="document">
				<div className="modal-content">

					<div className="modal-header">
						<h5 className="modal-title" id="select-crew">Employees</h5>
							<button type="button" className="close pt-5 pb-0" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>

					<div className="modal-body">
						<p className="strong fs-14 mb-2">Select Employees who are present for this Crew Inspection:</p>

						<p className="fs-16 strong">
							<a className="p-1">{workUnit.name}</a>
						</p>

						<div className="col-11 form-group input-group px-0">
							<input type="text" className="form-control" placeholder="Search for..." 
								value={employeeFilter}
								onChange={(e) => {handleChange("employeeFilter", e.target.value)}}
							/>
							<div className="input-group-append ml-4">
								<button className="btn btn-xs btn-secondary px-5" type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
							</div>
						</div>

						<div className="custom-controls-stacked ml-6">

							{employeesList.map((employee, index) => { 

								if (employeeFilter){
									let employeeToken = employee.name;
									if (!employee.name) employeeToken = employee.idemployee;

									if (employeeToken.toLowerCase().indexOf(employeeFilter.toLowerCase()) !== -1){
										return renderSelectEmployeeRow(employee, index)
									}
								} else {
									return renderSelectEmployeeRow(employee, index)
								}
								
							})}

						</div>

					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close">Add Employees</button>
					</div>
				</div>
			</div>
		</div>)
    }

    let renderSelectEmployeeRow = (employee, index) =>{

    	return(
    		<div className="custom-control custom-checkbox mb-1" key={index} onClick={() => setSelectedEmployee(index, employee.selected)} disabled={employee.email == createdby}>
				<input type="checkbox" className="custom-control-input" checked={employee.selected} />
				<label className="custom-control-label fs-16">{employee.name || employee.idemployee}</label>
			</div>
		)
    }


    return(<>

    	<div class="col-12 mt-2 pb-2 border-bottom">
			<div class="col-12 col-lg-6 pl-1 mb-2 clearfix">
				<p class="mt-4 mb-1 fs-17 float-left"><strong>Employees to be notified of required maintenance:</strong></p>
				<button class="btn btn-sm btn-round btn-secondary fs-12 mt-4 mb-3 float-left" data-toggle="modal" data-target="#select-crew">Select</button>
			</div>

			<div className="col-12 ml-5">
				<ul className="list-unstyled card-columns">
					{
						(getNumSelected(employeesList) > 0) ? 
							employeesList.map((employee, index) => {
								if (employee.selected){
									return <li className="strong" key={index}>{employee.name || employee.idemployee}</li>
								}
							})
						:
							<li className="ml-5"><em>None selected</em></li>
					}
				</ul>
			</div>
		</div>

		{renderSelectCrew()}

    </>)



}
import React, {useEffect, useRef} from 'react';

//Stores the previous value of a given state variable or prop.
//Only updates the prev value when the value changes; other implementations
//may update on every render instead.

export function usePrevious(value, initial) {
  const ref = useRef({target: value, previous: initial});

  if (ref.current.target !== value) {
    // The value changed.
    ref.current.previous = ref.current.target;
    ref.current.target = value;
  }

  return ref.current.previous;
}

//With thanks to https://dev.to/chrismilson/problems-with-useprevious-me
import _ from 'lodash';
import addBMPToLoggedBMPs from './addBMPToLoggedBMPs';

export default function (handleChange, reportState, existingBMP = false, firstIndex, entryIndex){

	let {loggedBMPs, activeBMPIndex, groupsList} = reportState;
	let newActiveBMPIndex = -1;

	if (existingBMP){
		newActiveBMPIndex = firstIndex;
	} else {
		newActiveBMPIndex = loggedBMPs.length;
		let bmp = groupsList[firstIndex].entries[entryIndex];
		addBMPToLoggedBMPs(loggedBMPs, bmp, handleChange);
	}

	handleChange("activeBMPIndex", newActiveBMPIndex);
    handleChange("currentBMPCorrected", false);
}
import _ from 'lodash';

export default function createVerifiedBMPList({groupsList, loggedBMPs, flexdata}){
	let {verifiedBMPList} = flexdata;

	if (!verifiedBMPList) verifiedBMPList = [];

	console.log("createVerifiedBMPsList params: groupsList:", groupsList, "loggedBMPs:", loggedBMPs, "verifiedBMPList:", verifiedBMPList);

	let toReturn = [];

	if (verifiedBMPList && verifiedBMPList.length){
		console.log("createVerifiedBMPsList loading existing verified BMPs with verifiedBMPList", verifiedBMPList)
		return loadExistingVerifiedBMPs(groupsList, loggedBMPs, verifiedBMPList);
	} else {
		console.log("creating new verified BMPs list")
		return createVerifiedBMPsList(groupsList, loggedBMPs);
	}

}

function loadExistingVerifiedBMPs(groupsList, loggedBMPs, passedVerifiedBMPList){

	let currentVerifiedBMPsList = createVerifiedBMPsList(groupsList, loggedBMPs);
	let toReturn = [];


	if (passedVerifiedBMPList.length != currentVerifiedBMPsList.length){
		let passedVerifiedBMPListIds = _.map(passedVerifiedBMPList, "idpractice")

		for (var i = 0; i < currentVerifiedBMPsList.length; i++){
			let entry = currentVerifiedBMPsList[i];
			let passedIndex = passedVerifiedBMPListIds.indexOf(entry.idpractice)

			if (passedIndex == -1){
				entry.status = "";
			} else {
				entry.status = passedVerifiedBMPList[passedIndex].status;
			}

			toReturn.push(_.cloneDeep(entry));
		}

		return toReturn;
	} else {
		return _.cloneDeep(passedVerifiedBMPList)
	}
}

function createVerifiedBMPsList(passedBMPGroups, passedLoggedBMPs){
	let bmpGroups = _.cloneDeep(passedBMPGroups);

	let loggedPractices = _.map(passedLoggedBMPs, "idpractice");
	let toReturn = [];
	let status = "";

	for (var i = 0; i < bmpGroups.length; i++){
		for (var j = 0; j < bmpGroups[i].entries.length; j++){
			let {name, type, idpractice} = bmpGroups[i].entries[j];


			if (loggedPractices.indexOf(idpractice) == -1){
				toReturn.push({name, type, idpractice, status});
			}

		}
	}

	return toReturn;
}
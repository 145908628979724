import React, {useState, useEffect, Fragment} from 'react';
import SummarySectionLineItem from './SummarySectionLineItem'

export default function SummarySectionDisplay({title, items, edit, showEdit, status, isMobileOnly}){

	return(<div class="col-12 col-md-6 px-0 float-left">
		<div class="card">
			<div class="card-body">

				<div class="col-12 mt-2 pb-3 px-0 clearfix">
					<div class="col-12 pl-1 mb-2 clearfix border-bottom">
						<p class="mt-4 mb-1 fs-20 float-left">{title}</p>
						{showEdit ? <button type="button" className={
							isMobileOnly ? "btn btn-xs btn-round btn-secondary fs-12 mb-3 ml-4 px-4 float-right" :
							"btn btn-xs btn-round btn-secondary fs-12 mt-4 mb-3 ml-4 px-4 float-right" 
						}
						onClick={edit}>
							Edit
						</button> : null}
					</div>

					{items && items.length ? items.map(({label, value, type, customEmptyState}, index) => {
						return <SummarySectionLineItem key={index} label={label} value={value} type={type} emptyStateString={customEmptyState !== undefined ? customEmptyState : status == "setup" ? "Not yet entered" : "None entered"} />
					}) : "Nothing to display..."}

				</div>
			</div>
		</div>
	</div>)

}
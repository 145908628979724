import React, {Fragment} from 'react'

export default function BMPFlowNav({reportState, handleChange}){

	let {phase} = reportState.flexdata;

	let pages = [
		{label: "BMP List", phase: "BMPInspection"},
		//{label: "BMP Map", phase: "BMPMap"},
		{label: "Logged", phase: "LoggedEventsList"}
	]

	return(<>
		<div class="col-lg-10 mx-auto mb-6 p-0">  
			<ul class="nav nav-tabs" role="tablist">

				{pages.map((page, key) => {
					return <li className="nav-item cursor" key={key}>
						<button className={"nav-link px-7" + (phase == page.phase ? " active" : "")} onClick={() => handleChange('phase', page.phase)}>{page.label}</button>
					</li>
				})}

			</ul>
		</div>
	</>)
}
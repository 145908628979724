import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import {usePrevious} from '../../../../../utils'

function RadioButtons({
	name, 
	onChange,
	defaultRadioVal, 
	buttons, 
	divClassName, 
	includeDiv, 
	inputClassName, 
	labelClassName,
	wrapperClassName,
	title
}){

	let [radioVal, setRadioVal] = useState(defaultRadioVal);
	let previousDefaultVal = usePrevious(defaultRadioVal)

	useEffect(() => {
		console.log("calling handleRadioChange from RadioButtons useEffect")
		onChange(radioVal)
	}, [radioVal])

	useEffect(() => {

		console.log("RadioButtons useEffect load defaultRadioVal:", defaultRadioVal)

		if (previousDefaultVal === "" && defaultRadioVal !== "" && typeof defaultRadioVal == "string"){
			setRadioVal(defaultRadioVal)
		}
		
	}, [defaultRadioVal])


	return <div className={wrapperClassName}>
		{title}

		{buttons.map(({label, value}, index) => {
			return <div className={divClassName} onClick={() => {setRadioVal(label)}} key={index}>

			    <input type="radio" className={inputClassName} name={name} value={label} checked={radioVal == label} id={name + "" + index} />
			    <label className={labelClassName} htmlFor={name + "" + index}>{label}</label>
	    	</div>
    	})}
	</div>

}

RadioButtons.defaultProps = {
	defaultRadioVal: null, 
	buttons: [{label: "Yes", value: "yes"}, {label: "No", value: "no"}, {label: "N/A", value: "na"}], 
	divClassName: "custom-control custom-radio", 
	includeDiv: true, 
	inputClassName: "custom-control-input", 
	labelClassName: "custom-control-label",
	wrapperClassName: "custom-controls-stacked",
	title: null,
}

export default RadioButtons;
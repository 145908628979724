import React, {Component, Fragment} from 'react'
import SuccessPopup from '../../../../common/Popups';
import { url } from '../../../../../constants'
import {LibraryService} from '../../../../../services'
import {handleFileUpload} from '../../../../../utils/handleFileUpload'

import _ from 'lodash';

export default class PhotoModal extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    handlePhotoUpload = (files) => {
    	let {callback} = this.props;

    	let truePicNum = (files.length + this.props.pictures.length)

    	if (truePicNum > 5 || truePicNum <= 0){
    		this.setState({fileUplodadLengthError: true})
    	} else {

    		handleFileUpload(files, "library", (fileNames) => {
			 // this.setState({uploadPopupTrigger: true});

			    //Add image to group array
			    let {pictures} = this.props;
			    let clonepictures = _.cloneDeep(pictures)
			    if (!clonepictures) clonepictures = [];

			    console.log("img upload Pictures before push:", clonepictures);
			    console.log("img upload filenames:", fileNames)
			    clonepictures.push.apply(clonepictures, fileNames);
			    console.log("img upload Pictures after push:", clonepictures)

			    callback(clonepictures);

			    this.setState({uploadPhotoSuccessPopup: true, fileUplodadLengthError: false});
			}, (error) => {
				console.log("img upload error", error)
			    this.setState({errorUploadingPhotoPopupTrigger: true})
			});

   
        }
    }
    

    handleDeletePhoto = async (photo) => {
    	let {pictures, callback} = this.props;
    	await LibraryService.deletePhoto({photosToDelete: [photo]});
    	let updatedPhotosArray = pictures.filter((item) => item !== photo);
    	callback(updatedPhotosArray);
    	this.setState({deletePhotoSuccessPopup: true});
    }


	render = () => {
        let {capitalizedModeName, pictures} = this.props;
        return(<Fragment>

        	<div className="modal fade" style={{zIndex: '2000', backgroundColor: "rgba(84,84,84,0.3)"}} id={"add-photos-"+this.props.uniqueID} tabindex="-1" role="dialog">
	            <div className="modal-dialog" role="document">
	                <div className="modal-content">

	                    <div className="modal-body">
	                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id={"close-upload-images-modal"+this.props.uniqueID}>
	                            <span aria-hidden="true">&times;</span>
	                        </button>
	                        <p className="strong fs-14 mb-2">Add images to illustrate this {capitalizedModeName}:</p>

	                        <div class="row">
	                        	<div className="custom-file col-4 mx-auto my-3">
                                    <input type="file" name="image" className="custom-file-input ml-2" id="customFile" accept=".png, .jpg, .jpeg" multiple
		                                onChange={(e) => {
		                                this.handlePhotoUpload(e.target.files);
		                            }} />
                                    <label className={"custom-file-label2 btn btn-circle p-4 mb-0 ml-6"} 
                                    for="customFileTruck">
                                        <i className="lead fa fa-camera"></i>
                                    </label>
                                </div> 
                            </div>

                            {pictures ? <div class="row">
	                            <div class="col-6 col-sm-3 px-1">
	                            	{pictures.map((image, index) => this.renderImage(image, index, 0, 4))}
	                            </div>
	                            <div class="col-6 col-sm-3 px-1">
	                                {pictures.map((image, index) => this.renderImage(image, index, 1, 4))}
	                            </div>
	                            <div class="col-6 col-sm-3 px-1">
	                                {pictures.map((image, index) => this.renderImage(image, index, 2, 4))}
	                            </div>
	                            <div class="col-6 col-sm-3 px-1">
	                                {pictures.map((image, index) => this.renderImage(image, index, 3, 4))}
	                            </div>
	                         </div> : null}
	                    </div>

	                    <div className="modal-footer">
		                	<button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close">Done</button>
		                </div>

	                </div>

	            </div>
	        </div>

	        <SuccessPopup
	            popupId={"truckIdErrorSettingPhoto"+this.props.uniqueID}
	            title="Oh no!"
	            message="Error Adding Images!"
	            trigger={this.state.errorUploadingPhotoPopupTrigger}
	            useButton={true}
	            style="warning"
	            callback={() =>  this.setState({errorUploadingPhotoPopupTrigger: false})}
	        />

	        <SuccessPopup
	            popupId={"uploadImagesLengthError"+this.props.uniqueID}
	            title="Error"
	            message="Maximum 5 images allowed"
	            trigger={this.state.fileUplodadLengthError}
	            useButton={true}
	            style="warning"
	            callback={() =>  this.setState({fileUplodadLengthError: false})}
	        />

	        <SuccessPopup
	            popupId={"truckIdPhotoUploadSuccess"+this.props.uniqueID}
	            title="Success!"
	            message={capitalizedModeName + " Images Added!"}
	            trigger={this.state.uploadPhotoSuccessPopup}
	            callback={() =>  {this.setState({uploadPhotoSuccessPopup: false})}}
	        />

	        <SuccessPopup
	            popupId={"truckIdPhotoUploadSuccess"+this.props.uniqueID}
	            title="Success!"
	            message={capitalizedModeName + " Images Added!"}
	            trigger={this.state.uploadPhotoSuccessPopup}
	            callback={() =>  {this.setState({uploadPhotoSuccessPopup: false})}}
	        />

        </Fragment>)
    }

    renderImage = (image, index, rowNum, totalRows) => {
        if (index % totalRows == rowNum){
            return <>
            	<button type="button" class="close mr-n3 p-2 strong" onClick={() => this.handleDeletePhoto(image)}>
				  <span>&times;</span>
				</button>
            	<img src={url.backend + "/images/practiceactions/" + image} className="img-fluid img-thumbnail p-3 mb-2 mx-3" data-provide="lightbox" alt="..." />
        		{/*<button type="button"  className="btn btn-small btn-outline-danger mx-auto">Delete</button>*/}
        	</>
        } else {
            return null;
        }
    }

}
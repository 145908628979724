import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import PhoneInput from 'react-phone-input-2'
import '../../../../People/WorkUnitCrew/phonestyles.css'
import DatePicker from 'react-datepicker' 
import RadioButtons from '../../EntryComponents/generic/RadioButtons'
import Dropdown from '../../EntryComponents/generic/Dropdown'
import textLimits from '../../constants/textLimits'

/*Second Page*/

export default function InspectionInfo(props){

	let {reportState, handleChange, saveReportForLater} = props;
	let {flexdata} = reportState;

	let [typeButtonsVal, setTypeButtonsVal] = useState(reportState.type);
	let [typeInputVal, setTypeInputVal] = useState("");

	useEffect(() => {

		if (typeButtonsVal != "Other (please specify)"){
			handleChange("type", typeButtonsVal)
		} else {
			handleChange("type", typeInputVal)
		}

	}, [typeButtonsVal, typeInputVal])


	return(<>
		<InspectionWrapper
	      title="Inspection Info"
	      navButtons={[
	        {
	          className: "btn-secondary", 
	          onClick: () => handleChange('phase', 'ProjectInfo'), 
	          label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
	        },
	        {
	          className: "btn-outline-primary",
	          onClick: saveReportForLater,
	          label: "Save for Later"
	        },
	        {
	          className: "btn btn-primary",
	          onClick: () => handleChange("phase", "WeatherAndSoil"),
	          label: <>CONTINUE <i class="fa fa-angle-right ml-1"></i></>
	        }
	      ]}
	    >

		<div class="col-12 float-left px-0 mb-4">
			<label class="text-primary">Inspection Date</label>
			<div class="col-12 contrast float-left px-0">
				<DatePicker
					className={"form-control strong fs-16"}
					dateFormat="MM/dd/yyyy hh:mm aa"
					selected = {reportState.date || null}
					onChange={ date => handleChange("date", date) }
					placeholderText="Date"
					showTimeSelect
	            />
			</div>
		</div>


		<div class="col-12 float-left px-0 mb-4">
			<label class="text-primary">Type of Inspection</label>
			<div class="col-12 contrast float-left px-0">
				<RadioButtons
					name="type"
					defaultRadioVal={typeButtonsVal}
					buttons={[
						{label: "Weekly", value: "weekly"},
						{label: "Precipitation Event", value: "precipitation"},
						{label: "Other (please specify)", value: "other"},
					]}
					onChange={(val) => setTypeButtonsVal(val)}
				/>
				
				{typeButtonsVal == "other" ?
					<input 
						class="form-control form-control-sm strong fs-16 col-8" 
						type="text" 
						placeholder="Specify Inspection Type"
						value={typeInputVal}
						onChange={(e) => setTypeInputVal(e.target.value)}
					 />
				: null}
			</div>
		</div>

		<Dropdown
			title="Name(s) of individual(s) performing inspection"
			value={flexdata.inspectionnames}
			onChange={(selectedList) => handleChange("inspectionnames", selectedList)}
			placeholder="Name(s)"
			dropdownValues={reportState.employeeNames}
			multiple={true}
			name="inspectionnames"
		/>

		{/*<div class="col-12 form-group contrast">
			<label class="text-primary"></label>
			<input class="form-control strong fs-16" 
				type="text" 
				
				value={flexdata.inspectionnames}
				maxLength={textLimits.textInputCharLimitExtra}
				
			/>
		</div>*/}

		<div class="col-12 form-group contrast">
			<label class="text-primary">Inspector Phone/Cell</label>
			<PhoneInput 
				autoFormat={true}
				country='us'
				onlyCountries={['us']}
				id="phonenumber"
				label="Onsite Phone/Cell"
				name="phonenumber"
				inputClass="form-control strong fs-16"
				dropdownClass="fs-16"
				placeholder="Onsite Phone/Cell"
				value={flexdata.inspectorphone} 
				onChange={phonenumber => handleChange('inspectorphone', phonenumber)}
			/>
		</div>

	</InspectionWrapper>

  
    </>)

}